import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import MockupWithContent from "../../components/MockupWithContent";
import _ from "lodash";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Header64 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty, defaultCtaOption } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpGraphics({
          activeOption: GraphicsOptions.image,
          userCanAddItems: false,
          maxItems: 2,

          label: {
            [GraphicsOptions.image]: "Upload the images:",
            [GraphicsOptions.video]: "Enter URL to Youtube video:",
            default: "Upload the images:",
          },

          fieldTitle: {
            [GraphicsOptions.image]: "Edit the screenshots",
            [GraphicsOptions.video]: "Video",
            default: "Edit the app screenshots",
          },
          labelTooltip: {
            [GraphicsOptions.image]: "",
            [GraphicsOptions.video]: "Video",
            default: "",
          },
          fieldInfo: {
            [GraphicsOptions.image]:
              "Upload images for mobile and desktop apps",
            [GraphicsOptions.video]: "",
            default: "Upload images for mobile and desktop apps",
          },
          items: [
            this.generator.createGraphicsContentObject({
              imageUrl: DEFAULT_IMAGES_CDN.UI_MOBILE_DASHBOARD,
              uploadButtonInfo: "mobile version",
              videoUrl:
                "https://up-blog-prod.s3.amazonaws.com/neon_compressed.mp4",
              width: 412,
              height: 892,
            }),
            this.generator.createGraphicsContentObject({
              imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_CALENDAR,
              uploadButtonInfo: "desktop version",
              videoUrl: "https://www.youtube.com/watch?v=tw4jkyfY4HE",
              width: 1280,
              height: 800,
            }),
          ],
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpTitle({
          titleText: "Because Your Product Deserves the Best",
          isDefaultEmpty
        })
      );
      this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
      this.props.saveContentInStore(
        this.generator.setUpMockups({
          items: [
            this.generator.createMockupsContentObject({
              type: "mobile",
              deviceId: "iphone_11_pro_max",
              optionId: "space_grey",
              fileName: "apple_iphone_11_pro_max_space_grey.png",
              humanTitle: "iPhone 11 Pro Max",
            }),
            this.generator.createMockupsContentObject({
              type: "desktop",
              deviceId: "apple_macbook_air",
              optionId: "space_grey",
              fileName: "apple_macbook_air_space_grey.png",
              humanTitle: "MacBook Air",
            }),
          ],
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpCta({
          activeOption: defaultCtaOption || callToActionOptions.buttons,
          isDefaultEmpty,

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "Get it",
              buttonHref: "/download-ios",
              buttonStyleType: buttonStyles.regular.typeTitle,
              buttonMobileAppStoreType: buttonStyles.mobileAppStore.ios,
              buttonColor: buttonStyles.regular.blackBg,
              buttonIsTargetBlank: false,
              pillPrefix: "Whoa!",
            }),

            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "About us",
              buttonHref: "/download-android",
              buttonStyleType: buttonStyles.regular.typeTitle,
              buttonMobileAppStoreType: buttonStyles.mobileAppStore.android,
              buttonColor: buttonStyles.regular.blackOutline,
              buttonIsTargetBlank: false,
              pillPrefix: "New",
            }),

            // ghost object
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.ghost.typeTitle,
              buttonColor: buttonStyles.ghost.accentOutline,
              buttonIsTargetBlank: false,
              pillPrefix: "View",
            }),
          ],
        })
      );
    }
  }

  render() {
    this.props.connectDefaultFormIntegration(this.props.componentItem);

    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let mobileGraphicsObject = this.props.componentItem.componentData.graphics
      .content.items[0];
    let desktopGraphicsObject = this.props.componentItem.componentData.graphics
      .content.items[1];

    let mobileMockup = displayTitleMarkupText(
      this.props.componentItem.componentData.mockups.content.items[0]
    );
    let desktopMockup = displayTitleMarkupText(
      this.props.componentItem.componentData.mockups.content.items[1]
    );

    const currentActiveOption: GraphicsOptions = _.get(
      this.props.componentItem,
      ["componentData", "graphics", "activeOption"]
    );

    return (
      <header
        className={`header-64 desktop-mockup-inside--${desktopMockup.deviceId} graphics-${currentActiveOption} default-graphics-${GraphicsOptions.image}`}
      >
        <div className="container container--large header-64__container">
          <div className="header-64__left">
            <div className="header-64__left_content">
              <div className="header-64__left_content_box">
                {title.length > 0 && (
                  <h1
                    className={
                      "title-text heading heading--accent header-64__heading  " +
                      (this.props.isDarkBg ? "text-white" : "")
                    }
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                )}

                {subtitleText.length > 0 && (
                  <p
                    className={
                      "subtitle-text header-64__text content_box " +
                      (this.props.isDarkBg ? "text-white" : "")
                    }
                    dangerouslySetInnerHTML={{ __html: subtitleText }}
                  />
                )}
                <div className="header-64__cta_box">
                  <WebsiteCTA
                    componentItem={this.props.componentItem}
                    isDarkBg={this.props.isDarkBg}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-64__right">
          <div className="header-64__right_wrapper">
            <div className="header-64__big_mockup">
              <MockupWithContent
                mockupObject={desktopMockup}
                graphicsObject={desktopGraphicsObject}
                isDarkBg={this.props.isDarkBg}
                currentActiveOption={currentActiveOption}
              />
            </div>
            <div className="header-64__small_mockup">
              <MockupWithContent
                mockupObject={mobileMockup}
                graphicsObject={mobileGraphicsObject}
                isDarkBg={this.props.isDarkBg}
                currentActiveOption={currentActiveOption}
                HTMLClassNames="graphics-HTML__editor-code_mobile-mockup graphics-HTML__editor-code_mobile-mockup_small"
                videoClassNames="graphics-video__video-element_mobile-mockup_small"
              />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header64;
