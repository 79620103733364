import React, { Component } from "react";
import "./Plan.css";
import { Popover } from "antd";
import "confetti-js";
import capitalizeString from "../../../../../helpers/strings/capitalizeString";
import default5 from "../../../../../img/avatars/default-5.svg";
import default6 from "../../../../../img/avatars/default-6.svg";
import default7 from "../../../../../img/avatars/default-7.svg";

import default0 from "../../../../../img/avatars/default-0.svg";
import default1 from "../../../../../img/avatars/default-1.svg";
import default2 from "../../../../../img/avatars/default-2.svg";
import default3 from "../../../../../img/avatars/default-3.svg";
import default4 from "../../../../../img/avatars/default-4.svg";
import DealsModal from "../DealsModal";
import { checkIfCurrentUserPlanSupportsDeals } from "../../../../../helpers/plans/checkIfCurrentUserPlanSupportsDeals";
import _ from "lodash";
import { connect } from "react-redux";
import {
  SaveUserEventsInStore,
  SetPaymentHistoryDataStatus,
  SetPaymentMethodDataStatus,
} from "../../../../../store/user/types";
import {
  setPaymentMethodDataStatus,
  setPaymentHistoryDataStatus,
  saveUserEventsInStore,
} from "../../../../../store/user/actions";
import PlanCTA from "./PlanCTA";
import PlanDescription from "./PlanDescription";
import PlanFeatures from "./PlanFeatures";
import CancelPlanModal from "../CancelPlanModal";

interface Props {
  user: any;
  payment: any;
  plans: any;
  meta: any;

  plan_id: number;
  title: string;
  description: string;
  monthly_price: number;
  paddle_plan_id: number;
  is_active: boolean;
  isFreePlan: boolean;
  price_per_website_per_month: number;
  userHasSubscription: boolean;

  activePricingPeriod: "monthly" | "yearly";

  setPaymentMethodDataStatus: SetPaymentMethodDataStatus;
  setPaymentHistoryDataStatus: SetPaymentHistoryDataStatus;
  saveUserEventsInStore: SaveUserEventsInStore;

  setIsCancelDiscountModalVisible: any;
}

class Plan extends Component<Props> {
  state = { isDealsModalVisible: false, isCancellationModalVisible: false };

  showCancellationModal = () => {
    this.setState({
      isCancellationModalVisible: true,
    });
  };

  handleCancellationOk = (e) => {
    this.setState({
      isCancellationModalVisible: false,
    });
  };

  handleCancellationCancel = (e) => {
    this.setState({
      isCancellationModalVisible: false,
    });
  };

  showDealsModal = () => {
    this.setState({
      isDealsModalVisible: true,
    });
  };
  hideDealsModal = (e) => {
    this.setState({
      isDealsModalVisible: false,
    });
  };


  render() {
    const { user, userHasSubscription } = this.props;

    // don't highlight the most popular plan when user already a pro of any plan
    let isHighlighted =
      !userHasSubscription &&
      this.props.title === "startup" &&
      !user.isLtd &&
      !user.isAppsumo;

    let startupUsersCount =
      (this.props.meta &&
        this.props.meta.dataFetched &&
        this.props.meta.items &&
        this.props.meta.items[0] &&
        this.props.meta.items[0].startup_plan_users_count) ||
      200; //200 is a default hardcode number (the amount of startup plan users in septemeber 2021)
    let startupPlanStatsJSX = (
      <div className="plan__card_users">
        <Popover
          content={
            <div>
              <div className="plan__card_users_faces_box">
                <img
                  className="plan__card_users_face plan__card_users_face--1"
                  src={default0}
                />
                <img
                  className="plan__card_users_face plan__card_users_face--2"
                  src={default1}
                />
                <img
                  className="plan__card_users_face plan__card_users_face--3"
                  src={default2}
                />
                <img
                  className="plan__card_users_face plan__card_users_face--4"
                  src={default3}
                />
                <img
                  className="plan__card_users_face plan__card_users_face--5"
                  src={default4}
                />
                <div className="plan__card_users_faces_plus">
                  +{startupUsersCount - 5}
                </div>
              </div>
              <div className="plan__card_users_info">
                <b>{startupUsersCount} people</b> are using <br />
                the Startup plan right now!
              </div>
            </div>
          }
        >
          <div className="plan__card_users_avatars">
            <img className="plan__card_users_avatar" src={default5} />
            <img
              className="plan__card_users_avatar plan__card_users_avatar--mid"
              src={default6}
            />
            <img className="plan__card_users_avatar" src={default7} />
          </div>
        </Popover>
      </div>
    );

    return (
      <div className="plan">
        <div
          className={
            "plan__card " + (isHighlighted ? " plan__card--focus " : " ")
          }
        >
          {isHighlighted && startupPlanStatsJSX}
          <div className="plan__top">
            <span className="plan__title">
              {capitalizeString(this.props.title)}
            </span>

            {this.props.isFreePlan && (
              <div className="plan__price_wrapper">
                <div className="plan__price plan__price--no-info">Free</div>
              </div>
            )}

            {this.props.isFreePlan === false && (
              <div className="plan__price_wrapper">
                <div className="plan__price_box">
                  <div className="plan__price">
                    <span>{this.props.monthly_price}</span>
                  </div>
                </div>
              </div>
            )}

            <span className="plan__card_info">
              <PlanDescription
                title={this.props.title}
                description={this.props.description}
                activePricingPeriod={this.props.activePricingPeriod}
                price_per_website_per_month={
                  this.props.price_per_website_per_month
                }
              />
            </span>

            {this.props.isFreePlan === false && (
              <div className="plan__cta_box">
                <PlanCTA
                  is_active={this.props.is_active}
                  monthly_price={this.props.monthly_price}
                  activePricingPeriod={this.props.activePricingPeriod}
                  title={this.props.title}
                  isHighlighted={isHighlighted}
                  plan_id={this.props.plan_id}
                  paddle_plan_id={this.props.paddle_plan_id}
                  userHasSubscription={userHasSubscription}
                  showCancellationModal={this.showCancellationModal}
                  setIsCancelDiscountModalVisible={this.props.setIsCancelDiscountModalVisible}
                />
              </div>
            )}
          </div>

          <div className="plan__features_box">
            <PlanFeatures
              title={this.props.title}
              showDealsModal={this.showDealsModal}
            />
          </div>
        </div>
        <DealsModal
          hideDealsModal={this.hideDealsModal}
          isDealsModalVisible={this.state.isDealsModalVisible}
          meta={this.props.meta}
          isCouponVisible={checkIfCurrentUserPlanSupportsDeals(
            this.props.user,
            this.props.plans
          )}
        />
        <CancelPlanModal
          visible={this.state.isCancellationModalVisible}
          onOk={this.handleCancellationOk}
          onCancel={this.handleCancellationCancel}
        />
      </div>
    );
  }
}

const mapActionsToProps = {
  setPaymentMethodDataStatus,
  setPaymentHistoryDataStatus,
  saveUserEventsInStore,
};
export default connect(null, mapActionsToProps)(Plan);
