import React, { useRef, useState } from "react";
import Modal, { ModalProps } from "antd/lib/modal";
import { Alert, Button, Divider, Icon, Tooltip } from "antd";
import "./CancelDiscountModal.scss";
import { UserState } from "../../../../store/user/types";
import { connect } from "react-redux";
import { PlansState } from "../../../../store/plans/types";
import { getCurrentPlan } from "../../../../helpers/plans/getCurrentPlan";
import { sendToDiscordAsync } from "../../../../helpers/sendToDiscordAsync";
import capitalizeString from "../../../../helpers/strings/capitalizeString";
import { showError } from "../../../../helpers/showError";
import _ from "lodash";
import { openNotification } from "../../../../helpers/openNotification";
import { api } from "../../../../data/urls";
import { AuthState } from "../../../../store/auth/types";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../../../helpers/auth/returnAuthHeaderForAJAX";
import { saveUserDataInState } from "../../../../store/user/actions";

interface Props extends ModalProps {
  user: UserState;
  plans: PlansState;
  auth: AuthState;
  saveUserDataInState: any;

  callbacks: any;
}

const CancelDiscountModal = (props: Props) => {
  const { user, plans, onCancel } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const currentPlan = getCurrentPlan(user, plans);

  const [isLoading, setIsLoading] = useState(false);

  const [step, setStep] = useState(1);

  const handleAcceptDiscount = () => {
    setIsLoading(true);
    sendToDiscordAsync({
      embeds: [
        {
          title: `🎁 User accepted discount`,
          description: `
              User Paddle ID: ${_.get(user, "data.paddle_subscription_id")}
              User next billing date: ${_.get(user, "data.next_payment_date")}
              User plan: ${capitalizeString(
                currentPlan.title
              )} ${capitalizeString(currentPlan.period)}
              User email: ${_.get(user, "data.email")}
              User id: ${_.get(user, "data.id")}
            `,
          color: 3447003,
        },
      ],
    })
      .then(() => {
        const apiUrl = api.account.updateUser + "/" + _.get(user, "data.id");
        const { accessToken } = props.auth;
        axios
          .patch(
            apiUrl,
            { is_agreed_to_discount: true },
            { ...returnAuthHeaderForAJAX(accessToken) }
          )
          .then(({ data }) => {
            props.saveUserDataInState({
              is_agreed_to_discount: data.is_agreed_to_discount,
            });
            openNotification(
              "Thanks for staying with us!",
              "We'll apply the discount within the next few days.",
              "OK",
              "success",
              10
            );
            onCancel(null);
            setStep(1);
            setIsLoading(false);
          })
          .catch((e) => {
            showError({});
            console.error(e);
            console.error(e.response);
            setIsLoading(false);
          });
      })
      .catch((e) => {
        showError({});
        console.error(e);
        console.error(e.response);
        setIsLoading(false);
      });
  };

  const isDiscountAccepted = _.get(user, "data.is_agreed_to_discount", false);

  return (
    <Modal
      {...props}
      title={
        <>
          {step === 1 && (
            <div style={{ fontSize: 20 }}>Is the price too high?</div>
          )}
          {step === 2 && (
            <div style={{ fontSize: 20 }}>Additional info</div>
          )}
        </>
      }
      centered
      footer={null}
      className="cancel-discount-modal"
      maskClosable={false}
      destroyOnClose
      onCancel={() => {
        onCancel(null);
        setStep(1);
      }}
    >
      {step === 1 && (
        <div ref={containerRef}>
          <p style={{ marginBottom: 18 }}>
            We'd love a chance to win you back. If cost was the problem, we can
            hook you up with a <b>50% discount</b> for <b>1 year</b>.
          </p>
          <div
            style={{
              display: "flex",
              gap: 10,
              flexDirection: "row-reverse",
            }}
          >
            <Tooltip
              overlayStyle={{
                zIndex: 9999999,
              }}
              title={
                isDiscountAccepted
                  ? "You already accepted the discount. It's applied within a few days. You can still cancel anytime."
                  : null
              }
              mouseLeaveDelay={0}
            >
              <Button
                type="primary"
                onClick={() => {
                  setStep(2);
                }}
                loading={isLoading}
                disabled={isDiscountAccepted || user.isCancellingProInProgress}
              >
                Get 50% off
              </Button>
            </Tooltip>
            <Button
              onClick={() => {
                if (props.callbacks["cancelDiscountCallback"]) {
                  props.callbacks["cancelDiscountCallback"]();
                  onCancel(null);
                }
              }}
              disabled={isLoading}
              loading={user.isCancellingProInProgress}
            >
              Cancel subscription
            </Button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div>
          <p style={{ marginBottom: 18 }}>
            We'll manually apply the discount to your subscription within the
            next few days. If you get charged before that, we'll refund 50% of
            the amount.
          </p>
          <div
            style={{
              display: "flex",
              gap: 10,
              flexDirection: "row-reverse",
            }}
          >
            <Button
              type="primary"
              onClick={handleAcceptDiscount}
              loading={isLoading}
              disabled={isDiscountAccepted || user.isCancellingProInProgress}
            >
              Ok, thanks
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
  plans: state.plans,
  auth: state.auth,
});

export default connect(mapStateToProps, { saveUserDataInState })(
  CancelDiscountModal
);
