import React, { Component } from "react";
import "./EditorScreenPageControls.css";
import { Button, Drawer, Icon, Modal, Popover, Tooltip } from "antd";
import { getCurrentWebsitePageUrl } from "../../helpers/getCurrentWebsitePageUrl";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import { indexWebsitePageDashboardUrl } from "../../data/constants";
import { getCurrentWebsitePage } from "../../helpers/getCurrentWebsitePage";
import { checkIfPageHasComponents } from "../../helpers/editor/checkIfPageHasComponents";
import { getFullPageUrl } from "../../helpers/editor/getFullPageUrl";
import addNoCacheParamToURL from "../../helpers/editor/addNoCacheParamToURL";
import SettingsDrawerContent from "./SettingsDrawerContent";
import _ from "lodash";
import PublishPageMenu from "./PublishPageMenu";
import { ChangePageItemGlobalData } from "../../store/websitePages/types";
import {
  ChangeWebsitesStateGlobalData,
  GetCurrentStoreData,
} from "../../store/websites/types";
import { getMetaDataFromComponentsArray } from "../../helpers/getMetaDataFromComponentsArray";
import UnsavedChangesModal from "./UnsavedChangesModal";
import { LoadStatus } from "../../enums/enums";
import { getCurrentPageId } from "../../helpers/getCurrentPageId";
import SkeletonLoader from "./SkeletonLoader";
import CmsSidebar from "./CmsSidebar";
import { ReactComponent as CmsIcon } from "../../img/icons/cms-outline-white.svg";

interface Props {
  user: any;
  websites: any;
  websitesPages: any;
  publishPage: any;
  history: any;
  saveWebsiteDataInStore: any;
  saveWebsitePageDataInStore: any;
  saveImageInfoInDB: any;
  removeImageFromDB: any;
  saveBlogDataInStore: any;
  saveBlogPostDataInStore: any;
  publishPost: any;
  drawerWidth: number | string;
  setDrawerWidth: any;
  changePageItemGlobalData: ChangePageItemGlobalData;
  getCurrentStoreData: GetCurrentStoreData;
  getPageLoadStatus: any;
  getSingleWebsiteLoadStatus: any;
  getSingleBlogLoadStatus: any;
  changeWebsitesStateGlobalData: ChangeWebsitesStateGlobalData;

  setUserStateData: any;
  isUserFreeNew: any;
}
interface MyState {
  openPageUrl: "";
  isSettingsDrawerVisible: boolean;
  isUnsavedChangesModalVisible: boolean;
  isCmsSidebarVisible: boolean;
}

class EditorScreenPageControls extends Component<Props, MyState> {
  componentWillMount(): void {
    this.setState({
      openPageUrl: "",
      isSettingsDrawerVisible: false,
      isUnsavedChangesModalVisible: false,
      isCmsSidebarVisible: false,
    });
  }

  showModal = () => {
    this.setState({
      isUnsavedChangesModalVisible: true,
    });
  };
  hideModal = () => {
    this.setState({
      isUnsavedChangesModalVisible: false,
    });
  };
  onDiscard = () => {
    this.setState({
      isUnsavedChangesModalVisible: false,
      isSettingsDrawerVisible: false,
    });
  };
  showDrawer = () => {
    this.setState({
      isSettingsDrawerVisible: true,
    });
  };
  hideDrawer = () => {
    const { currentWebsite } = this.props.getCurrentStoreData();
    if (
      _.get(currentWebsite, "hasUnsavedChanges") &&
      this.state.isSettingsDrawerVisible
    ) {
      this.showModal();
      return;
    }
    this.setState({
      isSettingsDrawerVisible: false,
    });
  };

  componentDidMount(): void {
    if (this.props.user.settings) {
      this.showDrawer();
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.user.settings && prevProps.user.settings !== this.props.user.settings) {
      this.showDrawer();
    }
  }

  render() {
    if (!this.props.websites.dataFetched) {
      return (
        <div>
          <SkeletonLoader width="128px" />
        </div>
      );
    }
    if (!this.props.user.dataFetched) {
      return (
        <div>
          <SkeletonLoader width="128px" />
        </div>
      );
    }

    let currentWebsite = getCurrentWebsite(this.props.websites.items);

    if (currentWebsite === undefined) {
      return null;
    }

    const fillTemplateProgress = _.get(
      currentWebsite,
      "fillTemplateData.status"
    );
    let currentWebsiteSubdomain = currentWebsite.subdomain;

    let websitesPagesArray = this.props.websitesPages.items;
    let currentWebsitePageUrl = getCurrentWebsitePageUrl(
      currentWebsite,
      websitesPagesArray
    );
    let currentWebsitePage = getCurrentWebsitePage(
      currentWebsitePageUrl,
      websitesPagesArray,
      currentWebsite
    );

    const pageId = getCurrentPageId();
    const { currentBlog } = this.props.getCurrentStoreData();

    const singlePageStatus = this.props.getPageLoadStatus(pageId);
    if (singlePageStatus === LoadStatus.ERROR) {
      return null;
    }
    if (singlePageStatus !== LoadStatus.LOADED) {
      return (
        <div>
          <SkeletonLoader width="128px" />
        </div>
      );
    }

    const singleWebsiteStatus =
      this.props.getSingleWebsiteLoadStatus(currentWebsite);
    if (singleWebsiteStatus !== LoadStatus.LOADED) {
      return (
        <div>
          <SkeletonLoader width="128px" />
        </div>
      );
    }

    const singleBlogStatus = this.props.getSingleBlogLoadStatus(currentBlog);
    if (singleBlogStatus !== LoadStatus.LOADED) {
      return (
        <div>
          <SkeletonLoader width="128px" />
        </div>
      );
    }

    if (currentWebsitePage === undefined) {
      return null;
    }

    let fullPageUrl = getFullPageUrl(currentWebsite, currentWebsitePageUrl);

    // when placing an URL ofr settings we must replace '' to indexWebsitePageDashboardUrl ('home').
    let currentWebsitePageUrlForSettings = currentWebsitePageUrl;
    if (currentWebsitePageUrlForSettings === "") {
      currentWebsitePageUrlForSettings = indexWebsitePageDashboardUrl;
    }

    let displayOpenOrPublishPageButtons = () => {
      let currentPageComponents = currentWebsitePage.page_components; //an object containing everything about the page content.

      // if a page contains some components
      if (checkIfPageHasComponents(currentPageComponents) === true) {
        /*Show the Open Page link is the page is published or offer to publish page if not.*/
        let ifAnyUnsavedChangesExist: boolean = false;
        ifAnyUnsavedChangesExist =
          this.props.websitesPages.websitePageNewEditionsDetected ||
          this.props.websites.isFooterEditionsDetected ||
          this.props.websites.isNavEditionsDetected;

        let ifAnyChangesBeingSaved: boolean = false;
        ifAnyChangesBeingSaved =
          this.props.websitesPages.isWaitingForSaveWebsitePageSchemeResponse ||
          this.props.websites.isWaitingForFooterEditionsServerResponse ||
          this.props.websites.isWaitingForNavEditionsServerResponse;

        let ifWaitingForPublishPageResponse =
          this.props.websitesPages.isWaitingForPublishPageResponse;

        if (this.props.isUserFreeNew()) {
          return (
            <Button
              htmlType="button"
              disabled={ifAnyUnsavedChangesExist}
              icon="eye"
              loading={ifWaitingForPublishPageResponse}
              type="primary"
              size="small"
              onClick={() => {
                const openPage = () => {
                  this.setState({
                    openPageUrl: addNoCacheParamToURL(fullPageUrl),
                  });
                  window.open(
                    addNoCacheParamToURL(fullPageUrl),
                    `pagePreview-${currentWebsitePage.id}`
                  );
                };
                if (currentWebsitePage.is_published) {
                  openPage();
                  return;
                }
                this.props.publishPage(
                  true,
                  currentWebsitePage.url,
                  currentWebsite,
                  null,
                  () => {
                    openPage();
                  }
                );
              }}
            >
              Preview page
            </Button>
          );
        }

        if (currentWebsitePage.is_published) {
          // if published, offer to open the page
          return (
            <a
              className="js-tour__open-page"
              onClick={() => {
                this.setState({
                  openPageUrl: addNoCacheParamToURL(fullPageUrl),
                });
              }}
              href={
                this.state.openPageUrl === ""
                  ? addNoCacheParamToURL(fullPageUrl)
                  : this.state.openPageUrl
              }
              target={`pagePreview-${currentWebsitePage.id}`}
            >
              <Button
                htmlType="button"
                type="primary"
                size="small"
                icon={ifAnyChangesBeingSaved ? "loading" : "link"}
              >
                Open page
              </Button>
            </a>
          );
        } else {
          const publishButtonJSX = (
            <Popover
              content={
                <PublishPageMenu
                  saveWebsitePageDataInStore={
                    this.props.saveWebsitePageDataInStore
                  }
                />
              }
              trigger="click"
              placement="bottomRight"
              destroyTooltipOnHide
              overlayClassName="no-arrow padding-12"
              overlayStyle={{
                display: ifAnyUnsavedChangesExist ? "none" : "block",
              }}
              onVisibleChange={(visible) => {
                if (!visible) return;

                const { currentWebsitePage } = this.props.getCurrentStoreData();

                if (!currentWebsitePage) return;

                const pageComponentItems = currentWebsitePage.page_components;

                if (!pageComponentItems) return;
                const {
                  first_page_title,
                  first_page_subtitle,
                  hero_image_url,
                  hero_image_uuid,
                } = getMetaDataFromComponentsArray(pageComponentItems);

                this.props.changePageItemGlobalData({
                  pageId: currentWebsitePage.id,
                  data: {
                    first_page_title,
                    first_page_subtitle,
                    hero_image_url,
                    hero_image_uuid,
                  },
                });
              }}
            >
              <Button
                htmlType="button"
                disabled={ifAnyUnsavedChangesExist}
                icon="eye"
                loading={ifWaitingForPublishPageResponse}
                type="primary"
                size="small"
              >
                Publish page
              </Button>
            </Popover>
          );

          // warn user to save changes before publishing the page because if he publish it without saving the components edition data he will lose them.
          if (ifAnyUnsavedChangesExist) {
            return (
              <Tooltip
                title={
                  <span>
                    Please <b>save changes</b> before publishing the page{" "}
                    <Icon type="arrow-down" />
                  </span>
                }
              >
                <div className="js-tour__publish">{publishButtonJSX}</div>
              </Tooltip>
            );
          } else {
            return <div className="js-tour__publish"> {publishButtonJSX} </div>;
          }
        }
      }
    };

    return (
      <>
        <ul className="editor-screen-page-controls">
          {fillTemplateProgress !== "inProgress" && (
            <>
              
                <li className="editor-screen-page-controls__item">
                  <Button
                    ghost
                    size="small"
                    onClick={() =>
                      this.props.changeWebsitesStateGlobalData({
                        isCmsSidebarVisible: true,
                      })
                    }
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      gap: 8,
                      position: "relative",
                      top: 2,
                    }}
                  >
                    <CmsIcon className="custom-icon-ghost-white" />
                    CMS
                  </Button>
                </li>
              
              <li className="editor-screen-page-controls__item">
                <Button
                  ghost
                  size="small"
                  type="default"
                  htmlType="button"
                  onClick={this.showDrawer}
                >
                  <Icon type="setting" />
                  Settings
                </Button>
              </li>
            </>
          )}
          <li className="editor-screen-page-controls__item">
            {displayOpenOrPublishPageButtons()}
          </li>
        </ul>
        <Drawer
          title="Settings"
          visible={this.state.isSettingsDrawerVisible}
          onClose={this.hideDrawer}
          className="settings-drawer"
          zIndex={1000}
          width={this.props.drawerWidth}
          afterVisibleChange={(visible) => {
            if (visible) {
              document.body.style.removeProperty("overflow");
              document.body.style.removeProperty("touch-action");
            }
          }}
          destroyOnClose
        >
          <SettingsDrawerContent
            history={this.props.history}
            saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
            saveWebsitePageDataInStore={this.props.saveWebsitePageDataInStore}
            publishPage={this.props.publishPage}
            saveImageInfoInDB={this.props.saveImageInfoInDB}
            removeImageFromDB={this.props.removeImageFromDB}
            type="page"
            saveBlogDataInStore={this.props.saveBlogDataInStore}
            saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
            publishPost={this.props.publishPost}
            setDrawerWidth={this.props.setDrawerWidth}
          />
        </Drawer>
        <Modal
          title="You have unsaved changes"
          visible={this.state.isUnsavedChangesModalVisible}
          onCancel={this.hideModal}
          centered
          width={300}
          footer={null}
        >
          <UnsavedChangesModal onDiscard={this.onDiscard} />
        </Modal>
        <Drawer
          title="CMS"
          headerStyle={{
            display: "none",
          }}
          visible={this.props.websites.isCmsSidebarVisible}
          onClose={() =>
            this.props.changeWebsitesStateGlobalData({
              isCmsSidebarVisible: false,
            })
          }
          zIndex={1050}
          width={1440}
          afterVisibleChange={(visible) => {
            if (visible) {
              document.body.style.removeProperty("overflow");
              document.body.style.removeProperty("touch-action");
            }
            if (!visible) {
              this.props.changeWebsitesStateGlobalData({
                cmsRerenderKey: this.props.websites.cmsRerenderKey + 1,
              });
            }
          }}
          className="cms-sidebar-drawer"
        >
          <CmsSidebar />
        </Drawer>
      </>
    );
  }
}

export default EditorScreenPageControls;
