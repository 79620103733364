import { sheetsApi } from "../data/urls";

export const formatGoogleSheetUrl = (url: string) => {
  if (typeof url !== "string") {
    return url;
  }
  if (!url.startsWith("https://docs.google.com/spreadsheets/")) {
    return url;
  }
  const outputParam = "output=csv";
  if (url.includes("pubhtml")) {
    url = url.replace(/\?/g, '&').replace("pubhtml", `pub?${outputParam}`)
  }
  let result = url
    .replace("output=tsv", outputParam)
    .replace("output=pdf", outputParam)
    .replace("output=xlsx", outputParam)
    .replace("output=ods", outputParam);
  return `${sheetsApi}/get-pages?sheet_url=${encodeURIComponent(result)}&v=2`;
};
