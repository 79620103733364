import React, { useEffect, useRef, useState } from "react";
import "./SettingsDrawerContent.scss";
import { Divider, Icon, Modal, Typography } from "antd";
import { connect } from "react-redux";
import {
  GetCurrentStoreData,
  SaveWebsiteBackup,
  WebsitesState,
} from "../../store/websites/types";
import { AuthState } from "../../store/auth/types";
import classNames from "classnames";
import WebsiteSettings from "./WebsiteSettings";
import WebsitePageSettings from "./WebsitePageSettings";
import AccountSettings from "./account/account_settings/AccountSettings";
import BlogSettings from "./BlogSettings";
import BlogPostSettings from "./BlogPostSettings";
import "./Settings.css";
import {
  getCurrentStoreData,
  saveWebsiteBackup,
} from "../../store/websites/thunks";
import InternalSettings from "./InternalSettings";
import { UserState } from "../../store/user/types";
import _ from "lodash";
import SaveChangesButtons from "./SaveChangesButtons";
import UnsavedChangesModal from "./UnsavedChangesModal";
import { setUserStateData } from "../../store/user/actions";

const { Text } = Typography;

export type ActiveMenu =
  | "account"
  | "account__plan"
  | "account__billing"
  | "account__applications"
  | "websiteSettings"
  | "customDomain"
  | "colorsAndFonts"
  | "widgets"
  | "exportHtml"
  | "pageSettings"
  | "blogSettings"
  | "postSettings"
  | "collaborators"
  | "internal__admin"
  | "internal__pageTemplate"
  | "cms"
  | "seo";
interface Props {
  user: UserState;
  websites: WebsitesState;
  auth: AuthState;
  getCurrentStoreData: GetCurrentStoreData;
  saveWebsiteBackup: SaveWebsiteBackup;
  setUserStateData: any;

  history: any;
  saveWebsitePageDataInStore: any;
  saveWebsiteDataInStore: any;
  saveBlogDataInStore: any;
  saveBlogPostDataInStore: any;
  publishPage: any;
  publishPost: any;
  saveImageInfoInDB: any;
  removeImageFromDB: any;
  type: "page" | "blog" | "starter";
  setDrawerWidth: any;
}

interface SidebarButtonGroupProps {
  children: React.ReactNode;
  title: string;
  condition?: boolean;
}
const SidebarButtonGroup = (props: SidebarButtonGroupProps) => {
  const { children, title, condition } = props;
  if (condition === false) return null;
  return (
    <div>
      <Text
        ellipsis={false}
        className="gpt-assistant__presets-dropdown-group-title"
      >
        {title}
      </Text>
      {children}
    </div>
  );
};
interface SidebarButtonProps {
  children?: React.ReactNode;
  menuTitle?: ActiveMenu;
  activeMenu?: ActiveMenu;
  setActiveMenu?: React.Dispatch<React.SetStateAction<ActiveMenu>>;
}
const SidebarButton = ({
  children,
  activeMenu,
  setActiveMenu,
  menuTitle,
}: SidebarButtonProps) => {
  const handleOnClick = () => {
    setActiveMenu(menuTitle);
  };
  return (
    <button
      className={classNames("settings-drawer__sidebar-button", {
        "is-active": activeMenu === menuTitle && !!activeMenu && !!menuTitle,
      })}
      onClick={handleOnClick}
    >
      {children}
    </button>
  );
};

interface SettingsMenuProps extends Props {
  currentMenuTitle: ActiveMenu;
  setActiveMenu: React.Dispatch<React.SetStateAction<ActiveMenu>>;
}
const SettingsMenu = (props: SettingsMenuProps) => {
  const { currentMenuTitle, setActiveMenu } = props;
  if (currentMenuTitle.startsWith("account")) {
    return (
      <AccountSettings
        history={props.history}
        currentMenuTitle={currentMenuTitle}
      />
    );
  }
  if (currentMenuTitle.startsWith("page")) {
    return (
      <WebsitePageSettings
        saveWebsitePageDataInStore={props.saveWebsitePageDataInStore}
        publishPage={props.publishPage}
        history={props.history}
        saveImageInfoInDB={props.saveImageInfoInDB}
        removeImageFromDB={props.removeImageFromDB}
        currentMenuTitle={currentMenuTitle}
      />
    );
  }
  if (currentMenuTitle.startsWith("blog")) {
    return (
      <BlogSettings
        saveImageInfoInDB={props.saveImageInfoInDB}
        removeImageFromDB={props.removeImageFromDB}
        saveBlogPostDataInStore={props.saveBlogPostDataInStore}
        saveBlogDataInStore={props.saveBlogDataInStore}
        publishPost={props.publishPost}
        currentMenuTitle={currentMenuTitle}
      />
    );
  }
  if (currentMenuTitle.startsWith("post")) {
    return (
      <BlogPostSettings
        saveBlogPostDataInStore={props.saveBlogPostDataInStore}
        publishPost={props.publishPost}
        saveImageInfoInDB={props.saveImageInfoInDB}
        removeImageFromDB={props.removeImageFromDB}
        currentMenuTitle={currentMenuTitle}
      />
    );
  }
  if (currentMenuTitle.startsWith("internal")) {
    return (
      <InternalSettings
        currentMenuTitle={currentMenuTitle}
        saveWebsitePageDataInStore={props.saveWebsitePageDataInStore}
      />
    );
  }
  return (
    <WebsiteSettings
      history={props.history}
      saveWebsitePageDataInStore={props.saveWebsitePageDataInStore}
      saveWebsiteDataInStore={props.saveWebsiteDataInStore}
      publishPage={props.publishPage}
      saveImageInfoInDB={props.saveImageInfoInDB}
      removeImageFromDB={props.removeImageFromDB}
      currentMenuTitle={currentMenuTitle}
      setActiveMenu={setActiveMenu}
    />
  );
};

const SettingsDrawerContent = (props: Props): JSX.Element => {
  const { type, getCurrentStoreData, saveWebsiteBackup } = props;
  const [activeMenu, setActiveMenu] = useState<ActiveMenu>(
    type === "blog"
      ? "postSettings"
      : type === "page"
      ? "colorsAndFonts"
      : "account"
  );

  useEffect(() => {
    if (props.user.settings) {
      setActiveMenu(props.user.settings as ActiveMenu);
      props.setUserStateData({
        settings: null,
      });
    }
  }, [props.user.settings])

  useEffect(() => {
    props.setDrawerWidth(activeMenu === "account__plan" ? "100%" : 640);
    return () => {
      props.setDrawerWidth(640);
    };
  }, [activeMenu]);
  const drawerRef = useRef<HTMLDivElement>(null);
  const isAdmin: boolean = _.get(props, "user.data.is_admin", false);
  const [isUnsavedChangesModalVisible, setIsUnsavedChangesModalVisible] =
    useState(false);
  const nextActiveMenu = useRef<ActiveMenu | null>(null);

  const onChangeActiveMenu: any = (menuTitle: ActiveMenu) => {
    const { currentWebsite } = getCurrentStoreData();
    if (
      _.get(currentWebsite, "hasUnsavedChanges") &&
      activeMenu === "colorsAndFonts" &&
      menuTitle !== "colorsAndFonts"
    ) {
      setIsUnsavedChangesModalVisible(true);
      nextActiveMenu.current = menuTitle;
      return;
    }
    setActiveMenu(menuTitle);
  };

  return (
    <div className="settings-drawer__content" ref={drawerRef}>
      <div className="settings-drawer__sidebar">
        <SidebarButtonGroup title="Blog post" condition={type === "blog"}>
          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="postSettings"
          >
            <Icon type="setting" />
            Blog post settings
          </SidebarButton>
        </SidebarButtonGroup>

        <SidebarButtonGroup title="Page" condition={type === "page"}>
          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="pageSettings"
          >
            <Icon type="setting" />
            Page settings
          </SidebarButton>
        </SidebarButtonGroup>

        {type !== "starter" && <Divider />}

        <SidebarButtonGroup title="Website" condition={type !== "starter"}>
          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="colorsAndFonts"
          >
            <Icon type="bg-colors" />
            Website theme
          </SidebarButton>
          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="websiteSettings"
          >
            <Icon type="setting" />
            General
          </SidebarButton>
          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="seo"
          >
            <Icon type="rocket" />
            SEO
          </SidebarButton>
          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="collaborators"
          >
            <Icon type="team" />
            Collaborators
          </SidebarButton>
          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="customDomain"
          >
            <Icon type="global" />
            Custom domain
          </SidebarButton>
          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="widgets"
          >
            <Icon type="appstore" />
            Widgets
          </SidebarButton>
          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="blogSettings"
          >
            <Icon type="read" />
            Blog
          </SidebarButton>
          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="exportHtml"
          >
            <Icon type="export" />
            Export HTML
          </SidebarButton>
        </SidebarButtonGroup>

        {type !== "starter" && <Divider />}

        <SidebarButtonGroup title="Account">
          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="account__plan"
          >
            <Icon type="crown" />
            Upgrade
          </SidebarButton>
          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="account__billing"
          >
            <Icon type="credit-card" />
            Billing
          </SidebarButton>
          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="account"
          >
            <Icon type="user" />
            Account settings
          </SidebarButton>

          <SidebarButton
            activeMenu={activeMenu}
            setActiveMenu={onChangeActiveMenu}
            menuTitle="account__applications"
          >
            <Icon type="apartment" />
            Connections
          </SidebarButton>
        </SidebarButtonGroup>
        {isAdmin && <Divider />}
        {isAdmin && (
          <SidebarButtonGroup title="Internal">
            <SidebarButton
              activeMenu={activeMenu}
              setActiveMenu={onChangeActiveMenu}
              menuTitle="internal__admin"
            >
              <Icon type="coffee" />
              Admin
            </SidebarButton>
            {type === "page" && (
              <SidebarButton
                activeMenu={activeMenu}
                setActiveMenu={onChangeActiveMenu}
                menuTitle="internal__pageTemplate"
              >
                <Icon type="star" />
                Page template
              </SidebarButton>
            )}
          </SidebarButtonGroup>
        )}
      </div>
      <div className="settings-drawer__menu">
        <SettingsMenu
          currentMenuTitle={activeMenu}
          setActiveMenu={
            onChangeActiveMenu as React.Dispatch<
              React.SetStateAction<ActiveMenu>
            >
          }
          {...props}
        />
      </div>
      {activeMenu === "colorsAndFonts" && <SaveChangesButtons />}
      <Modal
        title="You have unsaved changes"
        visible={isUnsavedChangesModalVisible}
        onCancel={() => setIsUnsavedChangesModalVisible(false)}
        centered
        width={300}
        footer={null}
      >
        <UnsavedChangesModal
          onDiscard={() => {
            setIsUnsavedChangesModalVisible(false);
            setActiveMenu(nextActiveMenu.current || "colorsAndFonts");
            nextActiveMenu.current = null;
          }}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: Props) => ({
  websites: state.websites,
  auth: state.auth,
  user: state.user,
});
const mapActionsToProps = { getCurrentStoreData, saveWebsiteBackup, setUserStateData };
export default connect(
  mapStateToProps,
  mapActionsToProps,
)(SettingsDrawerContent);
