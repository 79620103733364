import React, { useEffect, useRef, useState } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import _ from "lodash";
import axios from "axios";
import classNames from "classnames";
import { isKeyValue } from "../../helpers/isKeyValue";
import { lowerCaseKeys } from "../../helpers/lowercaseKeys";
import { GetCurrentStoreData, WebsitesState } from "../../store/websites/types";
import { apiUrlBase, sheetsApi } from "../../data/urls";
import {
  DEFAULT_DIRECTORY_2_ITEM_HTML,
  DEFAULT_DIRECTORY_3_ITEM_HTML,
  DEFAULT_DIRECTORY_ITEM_HTML,
} from "../../data/constants";
import { convertToNumber } from "../../helpers/convertToNumber";
import { unescapeCommas } from "../../helpers/unescapeCommas";
import { checkIfStringContainsProtocol } from "../../helpers/checkIfStringContainsProtocol";
import { getActiveDomain } from "../../helpers/websites/getActiveDomain";
import { slugifyNew } from "../../helpers/strings/slugifyNew";
import { FilterData } from "../../helpers/types/globalTypes";
import { CmsType } from "../../enums/enums";
import { getUniqueKeys } from "../../helpers/getUniqueKeys";
import { formatGoogleSheetUrl } from "../../helpers/formatGoogleSheetUrl";
import { getIsSheetsApiUrl } from "../../helpers/getIsSheetsApiUrl";
import { getFormattedCmsUrl } from "../../helpers/getFormattedCmsUrl";
import { getPageCollectionId } from "../../helpers/getPageCollectionId";

const DEBOUNCE_ROWS_COUNT = 100;

interface Props {
  websites: WebsitesState;
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
  getCurrentStoreData: GetCurrentStoreData;
}

interface ItemLoadingProps extends Props {}
const ItemLoading = (props: ItemLoadingProps) => {
  return <li className="directory-01__loading-item" />;
};

interface ShowMoreButtonProps extends Props {
  showAllItems: () => void;
  showMoreButtonText: string;
  clientRenderMode: string;
  renderItems: any;
}
const ShowMoreButton = (props: ShowMoreButtonProps) => {
  const {
    isDarkBg,
    showAllItems,
    showMoreButtonText,
    clientRenderMode,
    renderItems,
  } = props;
  return (
    <button
      className={classNames("button", {
        "button--white-bg": isDarkBg,
        "button--black-bg": !isDarkBg,
      })}
      onClick={(e) => {
        if (clientRenderMode !== "partial") {
          e.preventDefault();
          showAllItems();
        } else {
          e.preventDefault();
          renderItems(true);
        }
      }}
    >
      <span className="button__text">{showMoreButtonText || "Show more"}</span>
    </button>
  );
};

interface DirectoryItemProps extends Props {
  row: any;
  isShowMoreHidden: boolean;
  itemHtml: string;
  dynamicUrl: string;
  dynamicTarget: string;
  dynamicUrlPrefix: string;
  filtersV2Items: FilterData[];
}
const DirectoryItem = (props: DirectoryItemProps) => {
  const {
    isShowMoreHidden,
    itemHtml,
    row,
    dynamicUrl,
    dynamicTarget,
    dynamicUrlPrefix,
    getCurrentStoreData,
    filtersV2Items,
  } = props;
  const itemRef = useRef(null);
  const { currentWebsite } = getCurrentStoreData();

  function replaceWithObjectValues(html) {
    return html.replace(/\{\{(\w+)\}\}/g, function (match, p1) {
      let field = p1.toLowerCase();
      const isDynamicUrl = field === "dynamicurl";
      const isDynamicTarget = field === "dynamictarget";

      if (isDynamicUrl) {
        field = dynamicUrl;
      }

      let value = row[field] === undefined ? "" : row[field];

      if (isDynamicUrl && !checkIfStringContainsProtocol(value)) {
        value = slugifyNew(value);
        if (!value.startsWith("/")) {
          value = `/${value}`;
        }
        if (dynamicUrlPrefix && dynamicUrlPrefix !== "no_prefix") {
          value = `/${dynamicUrlPrefix}${value}`;
        }
        value = "https://" + getActiveDomain(currentWebsite) + value;
        if (
          (field || "").toString().startsWith("{{") ||
          (dynamicUrlPrefix || "").toString().startsWith("{{")
        ) {
          value = "https://" + getActiveDomain(currentWebsite);
        }
      }

      if (isDynamicTarget) {
        value = "_blank";
        if (dynamicTarget === "same_tab") {
          value = "_self";
        }
      }

      return value;
    });
  }

  const parentClassName = Object.keys(row).reduce((acc, key) => {
    const rowValue = (row[key] || "").toString().trim();
    if (!!rowValue && rowValue.toString().toLowerCase() !== "false") {
      return `${acc} dir-has-${key.toString().trim().replace(/ /g, "-")}`;
    }
    return acc;
  }, "directory-item-parent");

  const filterAttribute = {};
  filtersV2Items.forEach((filter) => {
    filterAttribute[filter.column] = Array.isArray(row[filter.column])
      ? row[filter.column].join(";")
      : row[filter.column];
  });

  return (
    <li
      style={{
        display: isShowMoreHidden ? "none" : "block",
      }}
      ref={itemRef}
      dangerouslySetInnerHTML={{
        __html: replaceWithObjectValues(itemHtml),
      }}
      className={parentClassName}
      data-filters={JSON.stringify(filterAttribute)}
    />
  );
};

interface TagsNewProps {
  topSectionHtml: string;
  rows: any[];
  searchPlaceholder: string;
  componentItem: any;
  isDarkBg: boolean;
}
const TopSection = (props: TagsNewProps) => {
  const { topSectionHtml, rows, searchPlaceholder, componentItem, isDarkBg } =
    props;

  const formattedSearchPlaceholder = (searchPlaceholder || "")
    .toString()
    .replace(/{{amount}}/g, rows.length.toString());

  const replaceUrlVariables = (text: string) => {
    if (typeof text !== "string") return text;
    const variables = {
      "{{searchPlaceholder}}": formattedSearchPlaceholder
        .replace(/\\/g, "\\\\")
        .replace(/'/g, "&#39;")
        .replace(/"/g, '\\"'),
      "{{id}}": componentItem.id,
      "{{amount}}": rows.length,
      "{{debounce}}":
        rows.length > 500
          ? "600ms"
          : rows.length > DEBOUNCE_ROWS_COUNT
          ? "300ms"
          : "1ms",
      "{{isDarkBg}}": isDarkBg,
      "{{filtersV2Items}}": JSON.stringify(
        _.get(
          componentItem,
          "componentData.directory.content.filtersV2.items",
          []
        )
      ).replace(/'/g, "&#39;"),
    };
    return text.replace(
      /{{searchPlaceholder}}|{{amount}}|{{id}}|{{debounce}}|{{isDarkBg}}|{{filtersV2Items}}/g,
      (matched) => {
        return variables[matched];
      }
    );
  };

  return (
    <div
      dangerouslySetInnerHTML={{ __html: replaceUrlVariables(topSectionHtml) }}
    />
  );
};

const Directory01 = (props: Props) => {
  const {
    componentItem,
    checkIsEmptyContent,
    saveContentInStore,
    isDarkBg,
    getCurrentStoreData,
    websites,
  } = props;
  const generator = new generateContentDataObject();

  const isMountedRef = useRef(false);
  const fullRowsRef = useRef([]);

  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [randomizedRows, setRandomizedRows] = useState([]);
  const [isShowMoreVisible, setIsShowMoreVisible] = useState(false);
  const [isNoItemsPlaceholderVisible, setIsNoItemsPlaceholderVisible] =
    useState(false);

  if (!isMountedRef.current) {
    let defaultHtml = DEFAULT_DIRECTORY_ITEM_HTML;
    if (componentItem.title === "02") {
      defaultHtml = DEFAULT_DIRECTORY_2_ITEM_HTML;
    }
    if (componentItem.title === "03") {
      defaultHtml = DEFAULT_DIRECTORY_3_ITEM_HTML;
    }
    const { isDefaultEmpty } = componentItem;
    if (checkIsEmptyContent()) {
      saveContentInStore(generator.setUpTitle({ isDefaultEmpty }));
      saveContentInStore(generator.setUpSubtitleText({ isDefaultEmpty }));
      saveContentInStore(
        generator.setUpDirectory({
          isDefaultEmpty,
          directoryItemHtml: defaultHtml,
        })
      );
      saveContentInStore(
        generator.addDirectoryFilterV2(componentItem.componentData, "filter1")
      );
    }
    saveContentInStore(
      generator.convertLegacyDirectoryToV2(componentItem.componentData)
    );
  }

  const replaceUrlVariables = (text: string) => {
    if (typeof text !== "string") return text;
    const { currentWebsite } = getCurrentStoreData();
    const variables = {
      "{{subdomain}}": _.get(currentWebsite, "subdomain"),
      "{{apiBase}}": apiUrlBase,
      "{{website_id}}": _.get(currentWebsite, "id"),
      "{{website_domain}}": getActiveDomain(currentWebsite),
    };
    return text.replace(
      /{{subdomain}}|{{apiBase}}|{{website_id}}|{{website_domain}}/g,
      (matched) => {
        return variables[matched];
      }
    );
  };

  const saveRowsToWindow = (rows) => {
    const componentId = componentItem.id;
    if (!window.uniDirectoryData) {
      window.uniDirectoryData = {
        [componentId]: rows,
      };
    } else {
      window.uniDirectoryData[componentId] = rows;
    }
  };

  const title = displayTitleMarkupText(
    componentItem.componentData.title.content.markup
  );
  const subtitleText = displaySubtitleMarkupText(
    componentItem.componentData.subtitleText.content.markup
  );
  const cmsUrl = _.get(
    componentItem,
    "componentData.directory.content.cmsUrl",
    ""
  );
  const renderMode = _.get(
    componentItem,
    "componentData.directory.content.renderMode",
    "client"
  );
  const isServerRenderActive =
    renderMode === "server" &&
    getIsSheetsApiUrl(
      getFormattedCmsUrl(
        _.get(componentItem, "componentData.directory.content")
      )
    );
  const clientRenderMode = _.get(
    componentItem,
    "componentData.directory.content.clientRenderMode",
    "full"
  );
  let maxItems = !isServerRenderActive
    ? parseInt(
        _.get(componentItem, "componentData.directory.content.maxItems") || 4
      )
    : 100;
  if (clientRenderMode === "partial" && maxItems > 100) {
    maxItems = 100;
  }
  const showMoreButtonText = _.get(
    componentItem,
    "componentData.directory.content.showMoreButtonText",
    ""
  );
  const itemHtml = _.get(
    componentItem,
    "componentData.directory.content.directoryItemHtml",
    ""
  );
  const sortingField = _.get(
    componentItem,
    "componentData.directory.content.sortingField",
    ""
  );
  const searchPlaceholder = _.get(
    componentItem,
    "componentData.directory.content.searchPlaceholder",
    ""
  );
  const filterFields = _.get(
    componentItem,
    "componentData.directory.content.filterFields",
    ""
  );
  const filterFieldsMode = _.get(
    componentItem,
    "componentData.directory.content.filterFieldsMode",
    "all"
  );
  const sortingOrder = _.get(
    componentItem,
    "componentData.directory.content.sortingOrder",
    "ascending"
  );
  const hasFeatureHideButton =
    _.get(
      componentItem,
      "componentData.directory.content.versions.button",
      1
    ) >= 2;
  const dynamicUrl = _.get(
    componentItem,
    "componentData.directory.content.dynamicUrl"
  );
  const dynamicTarget = _.get(
    componentItem,
    "componentData.directory.content.dynamicTarget"
  );
  const dynamicUrlPrefix = _.get(
    componentItem,
    "componentData.directory.content.dynamicUrlPrefix"
  );
  const updateFilterColumnsIndicator = _.get(
    componentItem,
    "componentData.directory.content.updateFilterColumnsIndicator",
    ""
  );
  const updateGlobalFilterIndicator = _.get(
    componentItem,
    "componentData.directory.content.updateGlobalFilterIndicator",
    ""
  );
  const notFoundPlaceholder = _.get(
    componentItem,
    "componentData.directory.content.notFoundPlaceholder",
    ""
  );
  const version = _.get(
    componentItem,
    "componentData.directory.content.version"
  );
  const moreItemsAmount = _.get(
    componentItem,
    "componentData.directory.content.moreItemsAmount",
    20
  );

  const saveFilters = () => {
    if (fullRowsRef.current.length === 0) return;
    const filtersV2Items = _.get(
      componentItem,
      "componentData.directory.content.filtersV2.items",
      []
    );
    filtersV2Items.forEach((filter) => {
      const filtersArray = [];
      fullRowsRef.current.forEach((row, i) => {
        const category = row[filter.column];
        if (!category) return;
        if (Array.isArray(category)) {
          filtersArray.push(...category);
        } else {
          filtersArray.push(
            ...category
              .toString()
              .split(";")
              .map((t) => t.trim())
          );
        }
      });
      const filters = [...[...new Set(filtersArray)]];
      saveContentInStore(
        generator.changeDirectoryFiltersV2(componentItem.componentData, {
          id: filter.id,
          filters,
        })
      );
    });
  };

  const saveGlobalFilters = () => {
    if (fullRowsRef.current.length === 0) return;
    const globalFilterItems = _.get(
      componentItem,
      "componentData.directory.content.globalItemFilters.filters",
      []
    );
    globalFilterItems.forEach((filter) => {
      const filtersArray = [];
      fullRowsRef.current.forEach((row) => {
        const value = row[filter.source];
        if (!value) return;
        if (Array.isArray(value)) {
          filtersArray.push(...value);
        } else {
          filtersArray.push(
            ...value
              .toString()
              .split(";")
              .map((t) => t.trim())
          );
        }
      });
      const filters = [...[...new Set(filtersArray)]];
      saveContentInStore(
        generator.changeGlobalItemFilter(componentItem.componentData, {
          id: filter.id,
          availableFilters: filters,
        })
      );
    });
  };

  const [partialRows, setPartialRows] = useState([]);

  const matchFilter = (itemFilterValues, selectedFilterValues) => {
    const itemValues = (itemFilterValues || "")
      .toString()
      .split(";")
      .map((value) => value.trim());
    return selectedFilterValues.some((value) => itemValues.includes(value));
  };

  const sortRowsFn = (a, b) => {
    if (!sortingField) return 0;
    if (sortingField === "no_sorting" || sortingField === "random_sorting") {
      return 0;
    }
    if (!(typeof sortingField === "string")) return 0;
    let field = sortingField.toLowerCase();
    let isNegative = false;
    if (sortingField.startsWith("-")) {
      field = sortingField.slice(1).toLowerCase();
      isNegative = true;
    }
    if (sortingOrder === "descending") {
      isNegative = !isNegative;
    }
    if (!Object.keys(a).includes(field)) return 0;
    if (!Object.keys(b).includes(field)) return 0;
    let aValue = a[field];
    let bValue = b[field];
    const isNumbers =
      !isNaN(convertToNumber(aValue)) && !isNaN(convertToNumber(bValue));

    if (isNumbers) {
      aValue = convertToNumber(aValue);
      bValue = convertToNumber(bValue);
      if (isNegative) {
        return bValue - aValue;
      }
      return aValue - bValue;
    }
    if (isNegative) {
      return bValue.toString().localeCompare(aValue);
    }
    return aValue.toString().localeCompare(bValue);
  };

  const renderItems = (
    addItems = false,
    amount = moreItemsAmount,
    clear = false
  ) => {
    if (!window.directoryDataPartial) return;
    const partialItem = window.directoryDataPartial[componentItem.id];
    if (!partialItem) return;

    if (clear) {
      partialItem.search = "";
      partialItem.selectedFilters = {};
    }

    const filtersV2Items = _.get(
      componentItem,
      "componentData.directory.content.filtersV2.items",
      []
    );

    const actualRows = fullRowsRef.current.filter(filterRowsGeneralFn);

    let rowsToAdd = actualRows;

    if (partialItem.search) {
      rowsToAdd = rowsToAdd.filter((row) => {
        const rowValues = Object.values(row).filter((value) => {
          return (
            !!value &&
            !(value || "").toString().toLowerCase().startsWith("https://") &&
            !(value || "").toString().toLowerCase().startsWith("http://")
          );
        });
        const rowValuesString = rowValues.join("");
        return (rowValuesString || "")
          .toString()
          .toLowerCase()
          .includes((partialItem.search || "").toString().toLowerCase());
      });
    }

    if (Object.keys(partialItem.selectedFilters).length > 0) {
      rowsToAdd = rowsToAdd.filter((row) => {
        const rowFilters = {};
        filtersV2Items.forEach((filter) => {
          rowFilters[filter.column] = Array.isArray(row[filter.column])
            ? row[filter.column].join(";")
            : row[filter.column];
        });
        const categories = Object.keys(partialItem.selectedFilters);
        return categories.every(
          (category) =>
            rowFilters.hasOwnProperty(category) &&
            matchFilter(
              rowFilters[category],
              partialItem.selectedFilters[category]
            )
        );
      });
    }

    rowsToAdd = rowsToAdd.sort(sortRowsFn);

    const totalRowsToAdd = rowsToAdd.length;

    if (!addItems) {
      partialItem.returnedItemsCount = 0;
      rowsToAdd = rowsToAdd.slice(0, maxItems);
      setPartialRows(rowsToAdd);
      if (rowsToAdd.length === 0) {
        setIsNoItemsPlaceholderVisible(true);
        setIsShowMoreVisible(false);
      } else {
        setIsNoItemsPlaceholderVisible(false);
      }
    } else {
      rowsToAdd = rowsToAdd.slice(
        partialItem.returnedItemsCount,
        partialItem.returnedItemsCount + amount
      );
      setPartialRows([...partialRows, ...rowsToAdd]);
    }

    partialItem.returnedItemsCount += rowsToAdd.length;

    if (totalRowsToAdd > partialItem.returnedItemsCount) {
      setIsShowMoreVisible(true);
    } else {
      setIsShowMoreVisible(false);
    }
  };

  useEffect(() => {
    const defaultPartialItem = {
      rows,
      maxItems,
      totalItemsCount: rows.length,
      renderItems,
      returnedItemsCount: rows.length > maxItems ? maxItems : rows.length,
      selectedFilters: {},
      search: "",
    };
    if (!window.directoryDataPartial) {
      window.directoryDataPartial = {
        [componentItem.id]: defaultPartialItem,
      };
    } else {
      window.directoryDataPartial[componentItem.id] = defaultPartialItem;
    }

    return () => {
      delete window.directoryDataPartial[componentItem.id];
    };
  }, [rows]);

  useEffect(() => {
    if (clientRenderMode === "partial") {
      renderItems(false, moreItemsAmount, true);
    }
  }, [clientRenderMode]);

  useEffect(() => {
    saveFilters();
  }, [updateFilterColumnsIndicator]);

  useEffect(() => {
    saveGlobalFilters();
  }, [updateGlobalFilterIndicator]);

  const filterRowsByFilterV2Fn = (row) => {
    if (renderMode === "server") return true;
    let displayRow = true;
    const filtersV2Items = _.get(
      componentItem,
      "componentData.directory.content.filtersV2.items",
      []
    );
    const itemsWithShowByFilters = filtersV2Items.filter((item) => {
      return !!item.showByFilters && item.showByFilters.items.length > 0;
    });
    itemsWithShowByFilters.forEach((filterV2Item: FilterData) => {
      const { mode, items } = filterV2Item.showByFilters;
      if (mode === "all") {
        const condition = items
          .split(";")
          .filter((i) => !!i)
          .every((item) => {
            let value = row[filterV2Item.column] || "";
            if (Array.isArray(value)) {
              value = value.join(";");
            }
            return value
              .toString()
              .split(";")
              .map((t) => t.trim())
              .includes(item);
          });
        if (!condition) {
          displayRow = false;
        }
      } else {
        const condition = items
          .split(";")
          .filter((i) => !!i)
          .some((item) => {
            let value = row[filterV2Item.column] || "";
            if (Array.isArray(value)) {
              value = value.join(";");
            }
            return value
              .toString()
              .split(";")
              .map((t) => t.trim())
              .includes(item);
          });
        if (!condition) {
          displayRow = false;
        }
      }
    });
    return displayRow;
  };

  const filterRowsByGlobalFiltersFn = (row) => {
    let displayRow = true;
    const globalItemFilterItems = _.get(
      componentItem,
      "componentData.directory.content.globalItemFilters.filters",
      []
    );
    globalItemFilterItems.forEach((filter) => {
      const selectedFilter = filter.selectedFilters[0];
      if (!selectedFilter) return;
      if ((filter.source || "").toString().startsWith("{{$")) {
        return;
      }
      if ((selectedFilter || "").toString().startsWith("{{$")) {
        return;
      }
      let value = row[filter.source] || "";
      if (!Array.isArray(value)) {
        value = value
          .toString()
          .split(";")
          .map((t) => t.trim());
      }

      if (!displayRow) return;
      if (selectedFilter === "unicorn-default-empty") {
        if (Array.isArray(value) && value.filter((v) => !!v).length > 0) {
          displayRow = false;
        }
      } else {
        if (!value.includes(selectedFilter)) {
          displayRow = false;
        }
      }
    });
    return displayRow;
  };

  const filterRowsByFieldsFn = (row) => {
    const checkFilterFieldsFn = (filterField) => {
      let isNegative = false;
      let field = filterField;
      if (filterField.startsWith("-")) {
        field = filterField.slice(1);
        isNegative = true;
      }
      if (!(field in row)) return true;
      const value = (row[field] || "").toString().trim();
      if (!value) return isNegative;
      if (value.toLowerCase() === "false") return isNegative;
      return !isNegative;
    };
    if (typeof filterFields !== "string" || !filterFields.trim()) {
      return true;
    }
    const filterFieldsArray = filterFields.split(";").map((t) => t.trim());
    if (filterFieldsMode === "any") {
      return filterFieldsArray.some(checkFilterFieldsFn);
    }
    return filterFieldsArray.every(checkFilterFieldsFn);
  };

  const filterRowsGeneralFn = (row) => {
    return (
      filterRowsByFieldsFn(row) &&
      filterRowsByFilterV2Fn(row) &&
      filterRowsByGlobalFiltersFn(row)
    );
  };

  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  useEffect(() => {
    setRows([...fullRowsRef.current].filter(filterRowsGeneralFn));
    if (clientRenderMode === "partial") {
      renderItems(false, moreItemsAmount, true);
    }
  }, [filterFields, filterFieldsMode]);

  const resetItemsVisibilityToDefault = () => {
    const rows = fullRowsRef.current.filter(filterRowsGeneralFn);
    document
      .querySelectorAll<HTMLElement>(
        `#${componentItem.id} .directory-item-parent`
      )
      .forEach((item, i) => {
        item.style.display =
          i < (isNaN(maxItems) ? 4 : maxItems) ? "block" : "none";
      });
    const showAllButton: HTMLElement = document.querySelector(
      `#${componentItem.id} .directory-01__show-more-box`
    );
    if (showAllButton) {
      showAllButton.style.display =
        (isNaN(maxItems) ? 4 : maxItems) < rows.length &&
        (showMoreButtonText || !hasFeatureHideButton || clientRenderMode === "partial") &&
        rows.length > 0
          ? "flex"
          : "none";
    }
  };

  const showAllItems = () => {
    document
      .querySelectorAll<HTMLElement>(
        `#${componentItem.id} .directory-item-parent`
      )
      .forEach((item) => {
        item.style.display = "block";
      });
    const showAllButton: HTMLElement = document.querySelector(
      `#${componentItem.id} .directory-01__show-more-box`
    );
    if (showAllButton) {
      showAllButton.style.display = "none";
    }
  };

  const updateItemsIndicator = _.get(
    componentItem,
    "componentData.directory.content.updateItemsIndicator",
    ""
  );
  const updateTopSectionIndicator = _.get(
    componentItem,
    "componentData.directory.content.updateTopSectionIndicator",
    ""
  );
  const cmsType =
    _.get(componentItem, "componentData.directory.content.cmsType") ||
    CmsType.GOOGLE_SHEETS;
  const unicornCollectionId = _.get(
    componentItem,
    "componentData.directory.content.unicornCollectionId",
    ""
  );
  const customApiUrl = _.get(
    componentItem,
    "componentData.directory.content.customApiUrl",
    ""
  );

  useEffect(() => {
    setRows([...fullRowsRef.current].filter(filterRowsGeneralFn));
    resetItemsVisibilityToDefault();
    if (clientRenderMode === "partial") {
      renderItems(false, moreItemsAmount, true);
    }
  }, [updateItemsIndicator]);

  useEffect(() => {
    setRows([]);
    fullRowsRef.current = [];
    if (clientRenderMode === "partial") {
      renderItems(false, moreItemsAmount, true);
    }
    setIsLoading(false);
    let formattedCmsUrl = "";
    const headers: any = {};
    if (cmsType === CmsType.UNICORN) {
      if (!unicornCollectionId) return;
      if (unicornCollectionId === "default-related-items") {
        const { currentWebsitePage } = getCurrentStoreData();
        const pageCollectionId = getPageCollectionId(currentWebsitePage);
        formattedCmsUrl = `${sheetsApi}/cms/items?collection_id=${pageCollectionId}`;
      } else {
        formattedCmsUrl = `${sheetsApi}/cms/items?collection_id=${unicornCollectionId}`;
      }
      headers["Cache-Control"] = "no-cache";
    }
    if (cmsType === CmsType.GOOGLE_SHEETS) {
      if (!cmsUrl) return;
      formattedCmsUrl = formatGoogleSheetUrl(replaceUrlVariables(cmsUrl));
    }
    if (cmsType === CmsType.CUSTOM) {
      if (!customApiUrl) return;
      formattedCmsUrl = replaceUrlVariables(customApiUrl);
    }
    if (
      !(
        formattedCmsUrl.startsWith("https://") ||
        formattedCmsUrl.startsWith("http://")
      )
    ) {
      return;
    }
    setIsLoading(true);
    axios
      .get(formattedCmsUrl, { headers })
      .then((response) => {
        let rows = [];

        let type = "text";
        if (Array.isArray(response.data)) {
          type = "jsonArray";
        }
        if (isKeyValue(response.data)) {
          type = "jsonObject";
        }

        let data = response.data;

        if (type === "jsonArray") {
          rows = data.map((row) => {
            return lowerCaseKeys(row);
          });
        } else if (type === "jsonObject") {
          if (
            cmsType === CmsType.GOOGLE_SHEETS &&
            formattedCmsUrl &&
            formattedCmsUrl.startsWith(`${sheetsApi}/get-pages`)
          ) {
            const items = _.get(data, "items", []);
            rows = items.map((row) => {
              return lowerCaseKeys(row);
            });
          } else {
            rows = [lowerCaseKeys(data)];
          }
        } else {
          rows = window.Papa.parse(data, {
            header: true,
          }).data.map((row) => {
            return lowerCaseKeys(unescapeCommas(row));
          });
        }

        rows.forEach((row) => {
          if ("title" in row) {
            row.slugified_title = slugifyNew(row.title);
          }
        });

        fullRowsRef.current = rows;
        saveContentInStore(
          generator.changeDirectoryAvailableColumns(
            componentItem.componentData,
            getUniqueKeys(rows || [])
          )
        );
        saveRowsToWindow(rows);
        rows = rows.filter(filterRowsGeneralFn);
        setRows(rows);
        if (clientRenderMode === "partial") {
          renderItems(false, moreItemsAmount, true);
        }
        setIsLoading(false);
        saveContentInStore(
          generator.changeDirectoryCurrentRowsSample(
            componentItem.componentData,
            rows.slice(0, 10)
          )
        );
        resetItemsVisibilityToDefault();
        saveFilters();
        saveGlobalFilters();
      })
      .catch((error) => {
        console.error(error);
        saveContentInStore(
          generator.changeDirectoryFilteredTags(componentItem.componentData, [])
        );
        setRows([]);
        fullRowsRef.current = [];
        if (clientRenderMode === "partial") {
          renderItems(false, moreItemsAmount, true);
        }
      });
  }, [
    cmsUrl,
    cmsType,
    unicornCollectionId,
    customApiUrl,
    websites.cmsRerenderKey,
  ]);

  const displayedRows = clientRenderMode === "partial" ? partialRows : rows;

  useEffect(() => {
    setRandomizedRows([...displayedRows].sort(() => Math.random() - 0.5));
  }, [displayedRows.length]);

  return (
    <div
      className={classNames("directory-01", {
        "dir-is-loading": isLoading,
        "directory-02": componentItem.title === "02",
        "directory-03": componentItem.title === "03",
        "directory-01--button-hidden":
          !showMoreButtonText && hasFeatureHideButton,
        "directory-01--search-hidden": !searchPlaceholder,
        "has-v2": version >= 2,
      })}
    >
      <div className="directory-01__box">
        <div className="container container--large directory-01__container">
          <div className="directory-01__text_box title-box title-box--center">
            {title.length > 0 && (
              <h1
                className={
                  "title-text heading heading--accent directory-01__heading " +
                  (isDarkBg ? "text-white" : "")
                }
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {subtitleText.length > 0 && (
              <div
                className={
                  "subtitle-text directory-01__subtitle content_box title-box__text " +
                  (isDarkBg ? "text-white" : "")
                }
                dangerouslySetInnerHTML={{ __html: subtitleText }}
              />
            )}
          </div>

          <div className="directory-01__parent-container">
            {!isServerRenderActive && !isLoading && rows.length > 0 && (
              <TopSection
                topSectionHtml={
                  componentItem.componentData.directory.content.topSectionHtml
                }
                rows={rows}
                searchPlaceholder={searchPlaceholder}
                componentItem={componentItem}
                isDarkBg={isDarkBg}
                key={updateTopSectionIndicator}
              />
            )}
            <div className="directory-01__bottom-container">
              {isLoading && (
                <ul className="directory-01__items">
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                </ul>
              )}
              {
                <ul className="directory-01__items">
                  {[...displayedRows].sort(sortRowsFn).map((row, i) => {
                    let isShowMoreHidden = false;
                    if (clientRenderMode !== "partial") {
                      if (i >= (isNaN(maxItems) ? 4 : maxItems)) {
                        isShowMoreHidden = true;
                      }
                    }
                    return (
                      <DirectoryItem
                        {...props}
                        key={i}
                        row={row}
                        isShowMoreHidden={isShowMoreHidden}
                        itemHtml={itemHtml}
                        dynamicUrl={dynamicUrl}
                        dynamicTarget={dynamicTarget}
                        dynamicUrlPrefix={dynamicUrlPrefix}
                        filtersV2Items={_.get(
                          componentItem,
                          "componentData.directory.content.filtersV2.items",
                          []
                        )}
                      />
                    );
                  })}
                </ul>
              }
              {!isLoading &&
                rows.length > 0 &&
                (isShowMoreVisible || clientRenderMode !== "partial") && (
                  <div
                    className={classNames("directory-01__show-more-box", {
                      "is-server-render-hidden": isServerRenderActive,
                    })}
                  >
                    <ShowMoreButton
                      {...props}
                      showAllItems={showAllItems}
                      showMoreButtonText={showMoreButtonText}
                      clientRenderMode={clientRenderMode}
                      renderItems={renderItems}
                    />
                  </div>
                )}
              {(isNoItemsPlaceholderVisible ||
                clientRenderMode !== "partial") &&
                !isServerRenderActive &&
                !!notFoundPlaceholder &&
                !isLoading &&
                rows.length > 0 && (
                  <div
                    className="directory-01__not-found-placeholder-container"
                    style={{
                      display:
                        clientRenderMode !== "partial" ? "none" : "block",
                    }}
                    dangerouslySetInnerHTML={{ __html: notFoundPlaceholder }}
                    key={
                      updateItemsIndicator +
                      updateTopSectionIndicator +
                      updateFilterColumnsIndicator
                    }
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Directory01;
