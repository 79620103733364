import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_TITLE,
  notificationDuration,
} from "../data/constants";
import { openNotification } from "./openNotification";

export const showError = ({
  title = DEFAULT_ERROR_TITLE,
  message = DEFAULT_ERROR_MESSAGE,
  duration = notificationDuration.medium,
  buttonText = "OK",
}: {
  title?: string;
  message?: string;
  duration?: number;
  buttonText?: string;
}) => {
  openNotification(title, message, buttonText, "error", duration);
};
