import { Button } from "antd";
import classNames from "classnames";
import React from "react";

interface Props {
  index: number;
  itemsLength: number;
  onMoveUp?: () => void;
  onMoveDown?: () => void;

  children?: JSX.Element[] | JSX.Element;
  noHover?: boolean;
  containerRef?: React.RefObject<HTMLDivElement>;
  onDelete?: () => void;
}

const Cluster = (props: Props): JSX.Element => {
  const {
    children,
    noHover,
    containerRef,
    onDelete,
    index,
    itemsLength,
    onMoveUp,
    onMoveDown,
  } = props;

  return (
    <div
      ref={containerRef}
      className={classNames("content-input__cluster", {
        "no-hover": noHover,
      })}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        marginBottom: 16,
      }}
    >
      {!!onDelete && (
        <Button
          onClick={onDelete}
          className="content-input__control_cluster"
          shape="circle"
          icon="cross"
          size="small"
        />
      )}
      {index > 0 && !!onMoveUp && (
        <Button
          className="content-input__control_cluster content-input__control_cluster--top"
          shape="circle"
          icon="up"
          size="small"
          onClick={onMoveUp}
        />
      )}
      {index < itemsLength - 1 && !!onMoveDown && (
        <Button
          className="content-input__control_cluster content-input__control_cluster--bottom"
          shape="circle"
          icon="down"
          size="small"
          onClick={onMoveDown}
        />
      )}
      {children}
    </div>
  );
};

export default Cluster;
