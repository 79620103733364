import axios from "axios";
import { api } from "../../data/urls";
import {
  SavePaymentMethodInState,
  SavePaymentsHistoryInState,
  SaveUserEventsInStore,
  SetPaymentHistoryDataStatus,
  SetPaymentMethodDataStatus,
  SetUserStateData,
  ToggleIsChangeUserEmailInProgress,
  ToggleIsDeleteAccountInProgress,
} from "./types";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import _ from "lodash";

const actionsNames = {
  SAVE_USER_DATA: "SAVE_USER_DATA" as string,
  USER_DATA_FETCHED: "USER_DATA_FETCHED" as string,
  TOGGLE_USER_PRO: "TOGGLE_USER_PRO" as string,
  TOGGLE_USER_LTD: "TOGGLE_USER_LTD" as string,
  TOGGLE_USER_APPSUMO: "TOGGLE_USER_APPSUMO" as string,
  TOGGLE_CANCELLING_PRO_IN_PROGRESS: "TOGGLE_CANCELLING_PRO_IN_PROGRESS" as string,
  TOGGLE_SWITCHING_PRO_PLAN_IN_PROGRESS: "TOGGLE_SWITCHING_PRO_PLAN_IN_PROGRESS" as string,
  TOGGLE_UPGRADING_FROM_FREE_TO_PRO_PLAN_IN_PROGRESS: "TOGGLE_UPGRADING_FROM_FREE_TO_PRO_PLAN_IN_PROGRESS" as string,

  SAVE_PAYMENT_METHOD: "SAVE_PAYMENT_METHOD" as string,
  SET_PAYMENT_METHOD_DATA_STATUS: "SET_PAYMENT_METHOD_DATA_STATUS",

  SAVE_PAYMENTS_HISTORY: "SAVE_PAYMENTS_HISTORY" as string,
  SET_PAYMENT_HISTORY_DATA_STATUS: "SET_PAYMENT_HISTORY_DATA_STATUS",

  TOGGLE_IS_CHANGE_USER_EMAIL_IN_PROGRESS:
    "TOGGLE_IS_CHANGE_USER_EMAIL_IN_PROGRESS",
  TOGGLE_IS_DELETE_ACCOUNT_IN_PROGRESS: "TOGGLE_IS_DELETE_ACCOUNT_IN_PROGRESS",
  SET_USER_EVENTS: "SET_USER_EVENTS",

  SET_USER_STATE_DATA: "SET_USER_STATE_DATA",

  ADD_TO_CLIPBOARD: "ADD_TO_CLIPBOARD",
  REMOVE_FROM_CLIPBOARD: "REMOVE_FROM_CLIPBOARD",
};

export const fetchCurrentUserInfo = (successCallback?: any) => {
  return (dispatch: any, getState: any) => {
    let accessToken = getState().auth.accessToken;
    axios
      .get(api.account.getCurrentUserInfo, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        dispatch(saveUserDataInState(response.data));
        dispatch(toggleUserDataFetched(true));
        const { first_name, email } = response.data;
        window.$crisp.push(["set", "user:email", [email]]);
        window.$crisp.push(["set", "user:nickname", [first_name]]);
        const paddleId: number = _.get(response, "data.paddle_subscription_id");
        if (paddleId) {
          window.profitwell("start", { user_id: paddleId });
        }

        if (typeof successCallback === "function") {
          successCallback();
        }
      })
      .catch((error) => {})
      .then((response) => {});
  };
};

export const saveUserDataInState = (data: string) => {
  // get user data and store it
  return {
    type: actionsNames.SAVE_USER_DATA as string,
    payload: data as string,
  };
};

export const toggleUserDataFetched = (isDataFetched: boolean) => {
  return {
    type: actionsNames.USER_DATA_FETCHED as string,
    payload: isDataFetched as boolean,
  };
};

export const toggleUserPro = (isPro: boolean) => {
  return {
    type: actionsNames.TOGGLE_USER_PRO as string,
    payload: isPro as boolean,
  };
};

export const toggleUserLtd = (isLtd: boolean) => {
  return {
    type: actionsNames.TOGGLE_USER_LTD as string,
    payload: isLtd as boolean,
  };
};
export const toggleUserAppsumo = (isAppsumo: boolean) => {
  return {
    type: actionsNames.TOGGLE_USER_APPSUMO as string,
    payload: isAppsumo as boolean,
  };
};

export const toggleCancellingPro = (isCancellingProInProgress: boolean) => {
  return {
    type: actionsNames.TOGGLE_CANCELLING_PRO_IN_PROGRESS as string,
    payload: isCancellingProInProgress as boolean,
  };
};

export const toggleSwitchingProPlanInProgress = (
  isSwitchingProPlanInProgress: boolean
) => {
  return {
    type: actionsNames.TOGGLE_SWITCHING_PRO_PLAN_IN_PROGRESS as string,
    payload: isSwitchingProPlanInProgress as boolean,
  };
};

export const toggleUpgradingFromFreeToProInProgress = (
  isSwitchingProPlanInProgress: boolean
) => {
  return {
    type: actionsNames.TOGGLE_UPGRADING_FROM_FREE_TO_PRO_PLAN_IN_PROGRESS as string,
    payload: isSwitchingProPlanInProgress as boolean,
  };
};

export const savePaymentMethodInState: SavePaymentMethodInState = (data) => {
  return {
    type: actionsNames.SAVE_PAYMENT_METHOD,
    payload: data,
  };
};
export const setPaymentMethodDataStatus: SetPaymentMethodDataStatus = (
  payload
) => {
  return {
    type: actionsNames.SET_PAYMENT_METHOD_DATA_STATUS,
    payload,
  };
};

export const savePaymentsHistoryInState: SavePaymentsHistoryInState = (
  data
) => {
  return {
    type: actionsNames.SAVE_PAYMENTS_HISTORY,
    payload: data,
  };
};
export const setPaymentHistoryDataStatus: SetPaymentHistoryDataStatus = (
  payload
) => {
  return {
    type: actionsNames.SET_PAYMENT_HISTORY_DATA_STATUS,
    payload,
  };
};

export const toggleIsChangeUserEmailInProgress: ToggleIsChangeUserEmailInProgress = (
  payload
) => {
  return {
    type: actionsNames.TOGGLE_IS_CHANGE_USER_EMAIL_IN_PROGRESS,
    payload,
  };
};

export const toggleIsDeleteAccountInProgress: ToggleIsDeleteAccountInProgress = (
  payload
) => {
  return {
    type: actionsNames.TOGGLE_IS_DELETE_ACCOUNT_IN_PROGRESS,
    payload,
  };
};

export const saveUserEventsInStore: SaveUserEventsInStore = (payload) => {
  return {
    type: actionsNames.SET_USER_EVENTS,
    payload,
  };
};

export const setUserStateData: SetUserStateData = (payload) => {
  return {
    type: actionsNames.SET_USER_STATE_DATA,
    payload,
  };
};

export const addToClipboard = (payload: any) => {
  return {
    type: actionsNames.ADD_TO_CLIPBOARD,
    payload,
  };
};

export const removeFromClipboard = (payload: any) => {
  return {
    type: actionsNames.REMOVE_FROM_CLIPBOARD,
    payload,
  };
};

export default actionsNames;
