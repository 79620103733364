import _ from "lodash";
import React, { useRef, useState } from "react";
import ContentInputLabel from "../../ui_components/ContentInputLabel";
import { Button, Select } from "antd";
import HorInputGroup from "../../ui_components/HorInputGroup";
import generateContentDataObject from "../../../helpers/editor/generateContentDataObject";
import Cluster from "../../ui_components/Cluster";

const { Option } = Select;

const generator = new generateContentDataObject();

interface MenuItemProps extends Props {
  filter: any;
  index: number;
  itemsLength: number;
  content: any;
  currentWebsitePage: any;
  usedSources: string[];
  availableColumns: string[];
  availableCmsProps: string[];
  cmsType: string;
}
const MenuItem = (props: MenuItemProps) => {
  const {
    filter,
    index,
    itemsLength,
    toggleNewEditionsDetected,
    saveContentInStore,
    content,
    currentWebsitePage,
    usedSources,
    availableColumns,
    availableCmsProps,
    cmsType,
  } = props;
  const [searchValue, setSearchValue] = useState("");
  const containerRef = useRef(null);
  const isValidationLoading: boolean = _.get(
    currentWebsitePage,
    "apiSourceValidationData.isLoading",
    false
  );
  
  const availableSourceColumns = [...availableColumns, ...availableCmsProps].filter(column => {
    if (column === filter.source) return true;
    return !usedSources.includes(column) || usedSources.indexOf(column) === usedSources.indexOf(filter.source);
  });
  
  const availableColumnsJsx = availableSourceColumns.map(
    (column) => {
      if (
        (column || "").toString().startsWith("{{") &&
        !searchValue.startsWith("{")
      ) {
        return null;
      }
      return (
        <Option key={column} value={column}>
          {column}
        </Option>
      );
    }
  );
  const tagFilterOptions = [
    ...filter.availableFilters,
    ...availableCmsProps,
  ].map((tag) => {
    if (
      (tag || "").toString().startsWith("{{") &&
      !searchValue.startsWith("{")
    ) {
      return null;
    }
    return (
      <Option key={tag} value={tag}>
        {tag}
      </Option>
    );
  });

  const deleteItem = () => {
    saveContentInStore(
      generator.deleteGlobalItemFilter(
        { directory: props.directory },
        filter.id
      )
    );
    toggleNewEditionsDetected(true);
  };

  return (
    <Cluster
      index={index}
      itemsLength={itemsLength}
      noHover
      onDelete={deleteItem}
    >
      <div ref={containerRef}>
        <HorInputGroup>
          <div>
            <ContentInputLabel>Source column</ContentInputLabel>
            <Select
              getPopupContainer={() => containerRef.current}
              showSearch
              placeholder="Select source"
              optionFilterProp="children"
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                (option.props.children as string)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              className="content-input__select"
              value={filter.source}
              onChange={(value) => {
                saveContentInStore(
                  generator.changeGlobalItemFilter(
                    { directory: props.directory },
                    { source: value, id: filter.id }
                  )
                );
                toggleNewEditionsDetected(true);
              }}
              onSearch={setSearchValue}
              onBlur={() => setSearchValue("")}
              onSelect={() => setSearchValue("")}
            >
              {availableColumnsJsx}
              {isValidationLoading && (
                <Option value="loading" disabled>
                  Loading global properties...
                </Option>
              )}
            </Select>
          </div>
          {filter.source && (
            <div>
              <ContentInputLabel>Value</ContentInputLabel>
              <Select
                getPopupContainer={() => containerRef.current}
                showSearch
                placeholder="Select filter"
                optionFilterProp="children"
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  (option.props.children as string)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                className="content-input__select"
                value={(filter.selectedFilters || [])[0]}
                onChange={(value) => {
                  saveContentInStore(
                    generator.changeGlobalItemFilter(
                      { directory: props.directory },
                      { selectedFilters: [value], id: filter.id }
                    )
                  );
                  toggleNewEditionsDetected(true);
                }}
                onSearch={setSearchValue}
                onBlur={() => setSearchValue("")}
                onSelect={() => setSearchValue("")}
              >
                <Option key={"unicorn-default-empty"} value={"unicorn-default-empty"}>
                  {"<empty>"}
                </Option>
                {tagFilterOptions}
                {isValidationLoading && (
                  <Option value="loading" disabled>
                    Loading global properties...
                  </Option>
                )}
              </Select>
            </div>
          )}
        </HorInputGroup>
      </div>
    </Cluster>
  );
};

interface AddItemButtonProps extends Props {
  allSourcesUsed: boolean;
}

const AddItemButton = (props: AddItemButtonProps) => {
  const { saveContentInStore, toggleNewEditionsDetected, allSourcesUsed } = props;

  const addItem = () => {
    saveContentInStore(
      generator.addGlobalItemFilter({ directory: props.directory })
    );
    toggleNewEditionsDetected(true);
  };

  const handleOnClick = () => {
    addItem();
  };

  // Don't render the button if all sources are used
  if (allSourcesUsed) {
    return null;
  }

  return (
    <div>
      <Button size="small" icon="plus" onClick={handleOnClick}>
        Add item filter
      </Button>
    </div>
  );
};

interface Props {
  directory: any;
  saveContentInStore: any;
  toggleNewEditionsDetected: any;
  currentWebsitePage: any;
}
const FilterItemsMenu = (props: Props) => {
  const {
    directory,
    currentWebsitePage,
  } = props;
  const content = _.get(directory, "content");
  if (!content) return null;
  const globalItemFilters = content.globalItemFilters || {};
  const filters = globalItemFilters.filters || [];
  const itemsLength = filters.length;
  
  const usedSources = filters.map(filter => filter.source).filter(source => !!source);

  const cmsType = _.get(content, "cmsType", "googleSheets");

  let availableColumns: string[] = _.get(
    content,
    "availableColumns",
    []
  ).filter((c) => !!c);

  if (cmsType === "unicorn") {
    availableColumns = availableColumns.filter(
      column => column === "filter1" || column === "filter2" || column === "filter3"
    );
  }
  
  const availableCmsProps: string[] = _.get(
    currentWebsitePage,
    "apiSourceValidationData.data.keys",
    []
  ).map((key: string) => `{{$${key}}}`);

  let allAvailableSources = [...availableColumns, ...availableCmsProps];
  
  const allSourcesUsed = usedSources.length >= allAvailableSources.length;

  return (
    <div>
      {filters.map((filter, index) => (
        <MenuItem
          key={filter.id}
          filter={filter}
          index={index}
          itemsLength={itemsLength}
          content={content}
          currentWebsitePage={currentWebsitePage}
          usedSources={usedSources}
          availableColumns={availableColumns}
          availableCmsProps={availableCmsProps}
          cmsType={cmsType}
          {...props}
        />
      ))}

      {filters.length < 10 && <div>
        <AddItemButton 
          {...props} 
          allSourcesUsed={allSourcesUsed}
        />
      </div>}
    </div>
  );
};

export default FilterItemsMenu;
