import axios, { AxiosResponse } from "axios";
import { apiUrlBase } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type PingDomainAsync = (payload: {
  accessToken: string;
  data: {
    domain: string;
  };
}) => Promise<AxiosResponse<any>>;

export const pingDomainAsync: PingDomainAsync = ({ accessToken, data }) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiUrlBase}/api/v1/meta/ping_domain?domain=${data.domain}`;
    axios
      .get(apiUrl, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
