import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";
import { WebsitesItem } from "../../store/websites/types";

type ConnectCustomDomainAsync = (payload: {
  accessToken: string;
  websiteId: number;
  operationType?: string;
  data: any;
}) => Promise<AxiosResponse<any>>;

export const connectCustomDomainAsync: ConnectCustomDomainAsync = ({
  accessToken,
  websiteId,
  data,
  operationType,
}) => {
  return new Promise((resolve, reject) => {
    let apiUrl = `${api.websites.connectCustomDomain}/${websiteId}`
    if (operationType) {
      apiUrl = `${apiUrl}?operation_type=${operationType}`
    }
    axios
      .post(apiUrl, data, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
