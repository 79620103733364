import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import templatePluginsInitializerClass from "../../helpers/editor/templatePluginsInitializerClass";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import Pictogram from "../component_molecules/Pictogram";
import LinkButtons from "../component_molecules/LinkButtons";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import { publicUrl } from "../../data/urls";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Tabs02 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Achieve More With Outstanding Website"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpGallery({
              isDefaultEmpty,
                fieldTitle: {default: "Content tabs"},
                label: {default: "Edit the tabs:"},
                fieldInfo: {default: 'Recommended screenshots: 750x1334px'},
                maxItems: 16,
                maxCtaButtons: 3,
                tabHasTitle: true,
                tabHasPictogram: true,
                contentHasCta: true,
                contentHasTitle: true,
                contentHasText: true,

                items: [
                    this.generator.createGalleryContentObject({
                        tabTitle: 'Explore',
                        contentGraphic: [this.generator.createGraphicsContentObject({
                            imageUrl: publicUrl + "/img/screenshots/app.png",
                        })],
                        contentTitle: 'Unleash Your Passion',
                        contentText: 'Rumor de germanus lamia, talem gemna! Galluss cadunt in chremisa! Sunt exsules contactus clemens, fidelis historiaes. Noster deuss ducunt ad rumor.',
                        ctaButtons: [
                            this.generator.createButtonContentObject({
                                buttonType: linkOrButtonOptions.link,
                                buttonTitle: "About us",
                                buttonHref: '/download',
                                buttonStyleType: buttonStyles.mobileAppStore.typeTitle,
                                buttonMobileAppStoreType: buttonStyles.mobileAppStore.ios,
                                buttonIsTargetBlank: false,
                                pillPrefix: 'Whoa!'
                            }),

                            this.generator.createButtonContentObject({
                                buttonType: linkOrButtonOptions.link,
                                buttonTitle: "About us",
                                buttonHref: '/download',
                                buttonStyleType: buttonStyles.mobileAppStore.typeTitle,
                                buttonMobileAppStoreType: buttonStyles.mobileAppStore.android,
                                buttonIsTargetBlank: false,
                                pillPrefix: 'Whoa!'
                            }),

                            // ghost object
                            this.generator.createButtonContentObject({
                                buttonType: linkOrButtonOptions.link,
                                buttonTitle: '',
                                buttonHref: '',
                                buttonStyleType: buttonStyles.ghost.typeTitle,
                                buttonColor: buttonStyles.ghost.accentOutline,
                                buttonIsTargetBlank: false,
                                pillPrefix: 'View'
                            })
                        ]
                    }),
                    this.generator.createGalleryContentObject({
                        tabTitle: 'Connect',
                        contentGraphic: [this.generator.createGraphicsContentObject({
                            imageUrl: publicUrl + "/img/screenshots/app2.png",
                        })],
                        contentTitle: 'Unlimited Opportunities',
                        contentText: 'Sunt vitaes acquirere noster, secundus elevatuses. Clemens eposs ducunt ad pars. Nobilis, varius nuclear vexatum iaceres satis locus de azureus, castus tus.',
                        ctaButtons: [
                            this.generator.createButtonContentObject({
                                buttonType: linkOrButtonOptions.link,
                                buttonTitle: "About us",
                                buttonHref: '/download',
                                buttonStyleType: buttonStyles.mobileAppStore.typeTitle,
                                buttonMobileAppStoreType: buttonStyles.mobileAppStore.ios,
                                buttonIsTargetBlank: false,
                                pillPrefix: 'Whoa!'
                            }),

                            this.generator.createButtonContentObject({
                                buttonType: linkOrButtonOptions.link,
                                buttonTitle: "About us",
                                buttonHref: '/download',
                                buttonStyleType: buttonStyles.mobileAppStore.typeTitle,
                                buttonMobileAppStoreType: buttonStyles.mobileAppStore.android,
                                buttonIsTargetBlank: false,
                                pillPrefix: 'Whoa!'
                            }),

                            // ghost object
                            this.generator.createButtonContentObject({
                                buttonType: linkOrButtonOptions.link,
                                buttonTitle: '',
                                buttonHref: '',
                                buttonStyleType: buttonStyles.ghost.typeTitle,
                                buttonColor: buttonStyles.ghost.accentOutline,
                                buttonIsTargetBlank: false,
                                pillPrefix: 'View'
                            })
                        ]

                    }),
                    this.generator.createGalleryContentObject({
                        tabTitle: 'Engage',
                        contentGraphic: [this.generator.createGraphicsContentObject({
                            imageUrl: publicUrl + "/img/screenshots/app.png",
                        })],
                        contentTitle: 'Explore New Horizons',
                        contentText: 'Nunquam desiderium saga. Fortis, emeritis calcarias inciviliter transferre de festus, audax resistentia. Festus, gratis poetas solite attrahendam de placidus.',
                        ctaButtons: [
                            this.generator.createButtonContentObject({
                                buttonType: linkOrButtonOptions.link,
                                buttonTitle: "About us",
                                buttonHref: '/download',
                                buttonStyleType: buttonStyles.mobileAppStore.typeTitle,
                                buttonMobileAppStoreType: buttonStyles.mobileAppStore.ios,
                                buttonIsTargetBlank: false,
                                pillPrefix: 'Whoa!'
                            }),

                            this.generator.createButtonContentObject({
                                buttonType: linkOrButtonOptions.link,
                                buttonTitle: "About us",
                                buttonHref: '/download',
                                buttonStyleType: buttonStyles.mobileAppStore.typeTitle,
                                buttonMobileAppStoreType: buttonStyles.mobileAppStore.android,
                                buttonIsTargetBlank: false,
                                pillPrefix: 'Whoa!'
                            }),

                            // ghost object
                            this.generator.createButtonContentObject({
                                buttonType: linkOrButtonOptions.link,
                                buttonTitle: '',
                                buttonHref: '',
                                buttonStyleType: buttonStyles.ghost.typeTitle,
                                buttonColor: buttonStyles.ghost.accentOutline,
                                buttonIsTargetBlank: false,
                                pillPrefix: 'View'
                            })
                        ]

                    }),
                ]

            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the Tabs component to invite users to read more about your project.",
                    default: "Add a button to the bottom of the Tabs component to invite users to read more about your project.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'See more'
                    }),
                ],

                priority: 10
            }));
        }
    }

    componentDidMount(): void {

    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let gallery = this.props.componentItem.componentData.gallery.content.items;

        let randomIndex = Math.floor(Math.random() * 9999); //because each Tabs component needs a unique index to switch the tabs.

        return (
            <section>
                <div className="tabs-02">

                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className="container">
                        <div className="tabs-02__box">

                            <ul className="tabs-02__buttons_list">

                                {gallery && gallery.map((item:any, key:number) => {
                                    if(item.tabTitle && item.tabTitle !== ''){
                                        return (
                                            <li className="tabs-02__button_item" key={"tabs-02-item-" + key}>
                                                {/* The first item is rendered as active. It may be changed on clicking (the jQuery handler will change it). But if the component is re-rendered, the first tab will be active again. */}
                                                <button
                                                    onClick={() => {
                                                        // We re-calc tabs height on every tab click because I do not know how to recalc tabs height when an image is loaded.
                                                        templatePluginsInitializerClass.setAllTabs();
                                                    }}

                                                    className={"iconed-tab__button js-open-tab " + (key === 0 ? 'state-active-tab' : '')}
                                                    type="button"
                                                    data-index={"tab-" + key}
                                                    data-group={"my-images-group-" + randomIndex}>

                                                    {item.tabPictogram.type && item.tabPictogram.type !== '' &&
													<div className="iconed-tab__button_icon">
                                                        <span className="icon">
                                                            <Pictogram 
                                                              pictogramObject={item.tabPictogram}
                                                              className='iconed-tab__button_image'
                                                            />
                                                        </span>
													</div>}
                                                    <div className="content-text def-12 iconed-tab__button_text">
                                                        { item.tabTitle }
                                                    </div>
                                                </button>
                                            </li>
                                        )
                                    }else{
                                        return (null);
                                    }
                                })}

                            </ul>

                            <div className="tabs-02__item_container">
                                <ul className="tabs-02__item_list js-tabs-item-list">

                                    {gallery && gallery.map((item:any, key:number) => {
                                        if(item.contentGraphic[0] !== undefined && item.contentGraphic[0].image !== undefined && item.contentGraphic[0].image.url !== ''){
                                            return (
                                                <li
                                                    key={"tabs-content-" + key}
                                                    className={"tabs-02__item_box js-tab-content " + (key === 0 ? 'state-active-tab' : '')}
                                                    data-index={"tab-" + key}
                                                    data-group={"my-images-group-" + randomIndex}>

                                                    <div className="tabs-02__item js-tab-content-item" data-height={item.contentGraphic[0].image.height ? item.contentGraphic[0].image.height : ''}>
                                                        <div className="tabs-02__visual_box">
                                                            <div
                                                                className="mockup  mockup--white mockup--iphone mockup--portrait ">
                                                                <div className="mockup__case">
                                                                    <div className="mockup__spacer"></div>
                                                                    <div className="mockup__speaker"></div>
                                                                    <div className="mockup__screen">
                                                                        <div className="mockup__screenshot"
                                                                             style={{backgroundImage: "url(" + formatImageUrl(item.contentGraphic[0].image.url) + ")"}}
                                                                        >

                                                                        </div>
                                                                    </div>
                                                                    <div className="mockup__button"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tabs-02__text_box">
                                                            <h2 className={"title-text--inner " + (this.props.isDarkBg ? 'text-white' : '')}>{item.contentTitle}</h2>
                                                            <div className={"content-text content_box " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: item.contentText}}/>
                                                            <div className="tabs-02__cta">
                                                                <LinkButtons 
                                                                  buttons={item.contentCta.buttons}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>


                                                </li>
                                            )
                                        }else{
                                            return (null);
                                        }
                                    })}

                                </ul>
                            </div>
                        </div>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Tabs02;
