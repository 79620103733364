import React from "react";
import { Button, Icon, Modal } from "antd";
import { ModalProps } from "antd/lib/modal";

interface Props extends ModalProps {}

const VersionMismatchModal = (props: Props) => {
  const {} = props;
  return (
    <Modal
      {...props}
      width={550}
      title={
        <div>
          <Icon type="warning" style={{ color: "#FFCC00", marginRight: 8 }} />
          Page version mismatch
        </div>
      }
      footer={null}
      centered
    >
      <div>
        <p>
          Looks like this page is being edited in other browser tabs too. Saving
          changes now might overwrite changes made in other tabs.
        </p>
        <p style={{ marginBottom: 21 }}>
          You can save the changes anyway, or you can refresh the page to sync
          with the server. Refreshing the page will discard current unsaved
          changes.
        </p>

        <div
          style={{
            display: "flex",
            gap: 10,
            flexDirection: "row-reverse",
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              props.onOk(null);
            }}
            icon="save"
          >
            Save changes anyway
          </Button>
          <Button
            type="primary"
            onClick={() => {
              window.location.reload();
            }}
            icon="reload"
          >
            Refresh page
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default VersionMismatchModal;
