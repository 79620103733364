import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Header44 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
      const { isDefaultEmpty, defaultCtaOption } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent(this.props.componentItem)){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;
            this.props.saveContentInStore(this.generator.setUpTitle({titleText: 'Welcome to the Future',isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: defaultCtaOption || callToActionOptions.buttons,
                priority: 500,

                formButtonStyleColor: buttonStyles.regular.whiteBg,
                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "Get it",
                        buttonHref: '/sign-up',
                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.whiteBg,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'Whoa!'
                    }),

                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "About us",
                        buttonHref: '/product',
                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.whiteOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'New'
                    }),

                    // ghost object
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.ghost.typeTitle,
                        buttonColor: buttonStyles.ghost.whiteOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'View'
                    })
                ]

            }));

        }
    }

    render(){
        this.props.connectDefaultFormIntegration(this.props.componentItem);

        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        return (
            <header className="header-44">
                <div className="container">
                    <div className="header-44__wrapper">
                        <div className="header-44__text_box">

                            {title.length > 0 && <h1 className={"title-text heading heading--accent "  + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: title}}/>}

                            {subtitleText.length > 0 && <p className={"subtitle-text content_box "  + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: subtitleText}}/>}

                            <div className="header-44__buttons_box">
                                <WebsiteCTA componentItem={this.props.componentItem} isDarkBg={this.props.isDarkBg} />
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        )
    }
}

export default Header44;
