import axios, { AxiosResponse } from "axios";
import { api, apiUrlBase } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type SyncComponentAsync = (payload: {
  accessToken: string;
  data: {
    componentId: string;
    websiteTemplateId: number;
    pageTemplateId: number;
    originalComponentId: string;
    componentType: string;
  };
}) => Promise<AxiosResponse<any>>;

export const syncComponentAsync: SyncComponentAsync = ({
  accessToken,
  data,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiUrlBase}/api/v1/website_pages/sync_component`;

    axios
      .post(apiUrl, data, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
