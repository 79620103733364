import React, {Component} from "react";
import generateContentDataObject from '../../helpers/editor/generateContentDataObject'
import {callToActionOptions} from "../../data/callToActionOptions";
import {pressLogos, publicUrl} from "../../data/urls";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {buttonStyles} from "../../data/buttonStyles";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import WebsiteLogo from "../component_molecules/WebsiteLogo";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Header04 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
      const { isDefaultEmpty, defaultCtaOption } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;

            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));

            this.props.saveContentInStore(this.generator.setUpLogos({
                maxItems: 16,
                priority: 100,
                items: [
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + pressLogos.prefix + 'white/tech-crunch.svg',
                        height: 25,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + pressLogos.prefix + 'white/forbes.svg',
                        height: 30,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + pressLogos.prefix + 'white/ycombinator.svg',
                        height: 30,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + pressLogos.prefix + 'white/lifehacker.svg',
                        height: 30,
                    }),

                    // The last ghost item which is used to add new items.
                    this.generator.createLogoContentObject({type: ''}),
                ],
                isDefaultEmpty
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
                activeOption: defaultCtaOption || callToActionOptions.buttons,
                formButtonStyleColor: buttonStyles.regular.altAccentBg,
                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "Get it",
                        buttonHref: '/sign-up',
                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.altAccentBg,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'Whoa!'
                    }),

                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "About us",
                        buttonHref: '/product',
                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.whiteBg,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'New'
                    }),

                    // ghost object
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.ghost.typeTitle,
                        buttonColor: buttonStyles.ghost.altAccentOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'View'
                    })
                ],
                isDefaultEmpty
            }));
        }
    }

    render(){
        this.props.connectDefaultFormIntegration(this.props.componentItem);

        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);
        let logos = this.props.componentItem.componentData.logos.content.items;

        return (
            <header className="header-04">
                <div className="header-04__box">
                    <div className="container container--mid header-04__container">

                        <div className="header-04__card header-04__card--top">
                            <div className="header-04__card_content">

                                {title.length > 0 && <h1 className={"title-text heading heading--accent header-04__heading " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: title}}/>}

                                {subtitleText.length > 0 && <p className={"subtitle-text header-04__text content_box "  + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: subtitleText}}/>}

                                <div className="header-04__buttons">
                                    <WebsiteCTA componentItem={this.props.componentItem} isDarkBg={this.props.isDarkBg} />
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            {logos.length > 1 && <div className="header-04__logos">
                                <div className="logos">
                                    <ul className="logos__list">

                                        {logos && logos.map((item:any, key:number) => {
                                            let logoItemContent;

                                            if(item.href && item.href !== ''){
                                                logoItemContent =
                                                    (<a
                                                        className="logos__item_link"
                                                        href={returnHref(item.href)}
                                                        target={item.isTargetBlank ? '_blank' : '_self'}>
                                                        <WebsiteLogo 
                                                          logoObject={item}
                                                          className='logos__img'
                                                        />
                                                    </a>)
                                            }else{
                                                logoItemContent =
                                                    (<span className=""><WebsiteLogo logoObject={item} className='logos__img' /></span>)
                                            }

                                            if(item.uploadedSrc && item.uploadedSrc !== ''){
                                                // If we have a logos library, implement a different check. Currently users can only _upload_ a logo.
                                                return (
                                                    <li key={key} className="logos__item">
                                                        {logoItemContent}
                                                    </li>
                                                )
                                            }else{
                                                return (null);
                                            }
                                        })}

                                    </ul>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header04;
