import actionsNames from "./actions";
import { BillingDataStatus } from "../../enums/BillingDataStatus";
import { UserData, UserState } from "./types";
import _ from "lodash";

let initialState: UserState = {
  data: {} as UserData,
  dataFetched: false,
  isPro: false,
  isLtd: false,
  isAppsumo: false,
  isCancellingProInProgress: false,
  isSwitchingProPlanInProgress: false,
  isUpgradingFromFreeToProInProgress: false,

  paymentMethod: undefined, // comes from https://developer.paddle.com/api-reference/subscription-api/users/listusers
  isSubscriptionInfoLoaded: false,
  paymentMethodDataStatus: BillingDataStatus.notLoaded,

  paymentsHistory: undefined, // comes from https://developer.paddle.com/api-reference/subscription-api/payments/listpayments
  isPaymentsHistoryLoaded: false,
  paymentHistoryDataStatus: BillingDataStatus.notLoaded,

  isChangeUserEmailInProgress: false,
  isDeleteAccountInProgress: false,

  isChangeAgreedToShareWebsitesInProgress: false,

  isLoggedIn: false,
  isSignedUp: false,
  authType: "",
  referrer: null,

  forceAdvancedEditorTabAsDefault: false,

  isInitialDataRequested: false,

  isCaptchaLoaded: false,

  clipboard: {},

  settings: null,
};

export const userReducer = (state: UserState = initialState, action: any) => {
  switch (action.type) {
    case actionsNames.SAVE_USER_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case actionsNames.USER_DATA_FETCHED:
      return {
        ...state,
        dataFetched: action.payload,
      };

    case actionsNames.TOGGLE_USER_PRO:
      return {
        ...state,
        isPro: action.payload,
      };

    case actionsNames.TOGGLE_USER_LTD:
      return {
        ...state,
        isLtd: action.payload,
      };
    case actionsNames.TOGGLE_USER_APPSUMO:
      return {
        ...state,
        isAppsumo: action.payload,
      };

    case actionsNames.TOGGLE_CANCELLING_PRO_IN_PROGRESS:
      return {
        ...state,
        isCancellingProInProgress: action.payload,
      };

    case actionsNames.TOGGLE_SWITCHING_PRO_PLAN_IN_PROGRESS:
      return {
        ...state,
        isSwitchingProPlanInProgress: action.payload,
      };

    case actionsNames.TOGGLE_UPGRADING_FROM_FREE_TO_PRO_PLAN_IN_PROGRESS:
      return {
        ...state,
        isUpgradingFromFreeToProInProgress: action.payload,
      };

    case actionsNames.SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: { ...action.payload },
      };
    case actionsNames.SET_PAYMENT_METHOD_DATA_STATUS: {
      return {
        ...state,
        paymentMethodDataStatus: action.payload,
      };
    }

    case actionsNames.SAVE_PAYMENTS_HISTORY:
      return {
        ...state,
        paymentsHistory: { ...action.payload },
      };
    case actionsNames.SET_PAYMENT_HISTORY_DATA_STATUS: {
      return {
        ...state,
        paymentHistoryDataStatus: action.payload,
      };
    }

    case actionsNames.TOGGLE_IS_CHANGE_USER_EMAIL_IN_PROGRESS: {
      return {
        ...state,
        isChangeUserEmailInProgress: action.payload,
      };
    }

    case actionsNames.TOGGLE_IS_DELETE_ACCOUNT_IN_PROGRESS: {
      return {
        ...state,
        isDeleteAccountInProgress: action.payload,
      };
    }
    
    case actionsNames.SET_USER_EVENTS: {
      return {
        ...state,
        data: {
          ...state.data,
          events: {
            ...state.data.events,
            ...action.payload,
          },
        },
      };
    }

    case actionsNames.SET_USER_STATE_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case actionsNames.ADD_TO_CLIPBOARD: {
      return {
        ...state,
        clipboard: {
          ...state.clipboard,
          [action.payload.key]: _.cloneDeep(action.payload.value),
        },
      };
    }

    case actionsNames.REMOVE_FROM_CLIPBOARD: {
      delete state.clipboard[action.payload.key];
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
