import { Select } from "antd";
import _ from "lodash";
import React, { useRef } from "react";
import generateContentDataObject from "../../../helpers/editor/generateContentDataObject";

const generator = new generateContentDataObject();

const { Option } = Select;

interface Props {
  directory: any;
  saveContentInStore: any;
  toggleNewEditionsDetected: any;
}
const RenderModeMenu = (props: Props) => {
  const { directory, saveContentInStore, toggleNewEditionsDetected } = props;
  const renderMode = _.get(directory, "content.renderMode", "client");
  const containerRef = useRef(null);

  const handleOnChange = (value: string) => {
    saveContentInStore(generator.changeRenderMode({ directory }, value));
    toggleNewEditionsDetected(true);
  };

  return (
    <div ref={containerRef}>
      <Select
        getPopupContainer={() => containerRef.current}
        style={{ width: 230 }}
        value={renderMode}
        onChange={handleOnChange}
      >
        <Option value="client">Render on client</Option>
        <Option value="server">Render on server</Option>
      </Select>
    </div>
  );
};

export default RenderModeMenu;
