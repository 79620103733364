import axios, { AxiosResponse } from "axios";
import { urls } from "../data/urls";

interface DiscordEmbed {
  title: string;
  description: string;
  color: number;
}

interface Data {
  embeds: DiscordEmbed[];
  url?: string;
}

export const sendToDiscordAsync = (
  data: Data,
): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    axios
      .post(data.url ? data.url : urls.discordWebhooks.leads, data)
      .then((response) => resolve(response))
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
