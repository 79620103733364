import Modal, { ModalProps } from "antd/lib/modal";
import React from "react";
import { openChatWidget } from "../../helpers/editor/openChatWidget";

interface Props extends ModalProps {}
const FailedPaymentModal = (props: Props) => {
  return (
    <Modal
      {...props}
      zIndex={200100000}
      centered
      footer={null}
      title="Alternative payment methods"
      maskClosable={false}
    >
      <div>
        <p>
          Seems like your payment didn't go through. This could be due to a few
          reasons - Paddle might not be available in your country, or there
          might be insufficient funds in your account.
        </p>
        <p>
          If funds aren't the issue, we have alternative payment methods
          available, including Stripe and crypto. You can{" "}
          <a
            href="https://unicornplatform.com/annual-service-plans/"
            target="_blank"
            className="underlined_link"
          >
            check them out here
          </a>
          .
        </p>
        <p>
          If you have any questions, feel free to ask in the{" "}
          <button
            onClick={openChatWidget}
            className="underlined_link"
            style={{ cursor: "pointer", color: "#1890ff" }}
          >
            support chat
          </button>{" "}
          or via{" "}
          <a
            href="https://x.com/johnrushx"
            target="_blank"
            className="underlined_link"
          >
            DM
          </a>
          .
        </p>
        <p style={{ marginBottom: 0 }}>
          Best,
          <br />
          John, CEO
        </p>
      </div>
    </Modal>
  );
};

export default FailedPaymentModal;
