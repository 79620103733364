import React, { Component } from "react";
import { Badge, Button, Icon, Tooltip } from "antd";
import "./WebsiteRedirects.css";
import { api } from "../../data/urls";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { openNotification } from "../../helpers/openNotification";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import WebsiteRedirectItem from "./WebsiteRedirectItem";
import generateRandomNumerousId from "../../helpers/generateRandomNumerousId";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import _ from "lodash";

interface Props {
  auth: any;
  user: any;
  websites: any;
  currentWebsite: any;
  fullWebsiteUrl: any;
  toggleWaitingForCommonWebsiteUpdate: any;
  saveWebsiteDataInStore: any;
}

class WebsiteRedirects extends Component<Props> {
  redirectUrlPlaceholder = "domain.com"; //default
  componentDidMount(): void {
    if (
      this.props.currentWebsite.custom_domain &&
      this.props.currentWebsite.custom_domain !== ""
    ) {
      //if has custom domain
      this.redirectUrlPlaceholder = this.props.currentWebsite.custom_domain;
    }
    this.redirectUrlPlaceholder = "https://" + this.redirectUrlPlaceholder;
    this.redirectUrlPlaceholder = this.redirectUrlPlaceholder + "/our-team";
  }

  changeRedirectsObjectOnServer = (newRedirectsObject: any, callback?: any) => {
    this.props.toggleWaitingForCommonWebsiteUpdate(true);

    let accessToken = this.props.auth.accessToken;

    let apiUrl =
      api.websites.updateWebsite.prefix +
      this.props.currentWebsite.subdomain +
      api.websites.updateWebsite.postfix;
    axios
      .patch(
        apiUrl,
        { redirects: newRedirectsObject },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then(() => {
        const dataToStore = {
          data: {
            ...this.props.currentWebsite,
            redirects: newRedirectsObject,
          },
        };
        this.props.saveWebsiteDataInStore(
          dataToStore,
          this.props.currentWebsite.subdomain
        );
        if (callback) {
          callback();
        }
        // Tell a user that the operation is successful.
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);
          handleBadWebsiteError(errorData);
          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleWaitingForCommonWebsiteUpdate(false);
      });
  };
  createNewRedirectItemObject = () => {
    return {
      currentRedirectFromUrl: "example-from",
      currentRedirectDestinationUrl: "example-to",
      connectedOn: Date.now(),
      id:
        "redirect-" +
        this.props.currentWebsite.subdomain +
        "-" +
        generateRandomNumerousId().toString(), // e.g.: "redirect-my_app-8142"
    };
  };

  addNewRedirectItem = () => {
    let oldRedirectsObject = this.props.currentWebsite.redirects;
    // fireAnalyticsEvent.deactivateDisallowAllRobotsError(this.props.currentWebsite.subdomain, errorObjectKey, errorObjectValue);

    if (oldRedirectsObject.items === undefined) {
      // if the object is empty, create the properties
      oldRedirectsObject = { items: [], settings: {} };
    }

    oldRedirectsObject.items.push(this.createNewRedirectItemObject());
    this.changeRedirectsObjectOnServer(oldRedirectsObject);
  };

  deleteRedirectItem = (itemToDeleteId: number) => {
    let newRedirectsObject = this.props.currentWebsite.redirects;
    let newRedirectsList = newRedirectsObject.items;
    if (newRedirectsList) {
      //delete from the array
      for (let i = 0; i < newRedirectsList.length; i++) {
        if (newRedirectsList[i].id === itemToDeleteId) {
          newRedirectsList.splice(i, 1);
          break;
        }
      }

      this.changeRedirectsObjectOnServer(newRedirectsObject);
    } else {
      openNotification(
        "Bang!",
        "You tried to delete a redirection which does not exist yet. This should not have happened. Please contact us.",
        "Okay",
        "error"
      );
    }
  };

  editRedirectItem = (
    itemToEditId: number,
    newRedirectFromUrl: string,
    newRedirectDestinationUrl: string,
    callback: any
  ) => {
    let newRedirectsObject = this.props.currentWebsite.redirects;
    let newRedirectsList = newRedirectsObject.items;
    if (newRedirectsList) {
      //delete from the array
      for (let i = 0; i < newRedirectsList.length; i++) {
        if (newRedirectsList[i].id === itemToEditId) {
          newRedirectsList[i].currentRedirectFromUrl = newRedirectFromUrl;
          newRedirectsList[i].currentRedirectDestinationUrl =
            newRedirectDestinationUrl;
          break;
        }
      }

      this.changeRedirectsObjectOnServer(newRedirectsObject, callback);
    } else {
      openNotification(
        "Whoa!",
        "You tried to edit a redirection which does not exist yet. This should not have happened. Please contact us.",
        "Okay",
        "error"
      );
    }
  };

  render() {
    let redirectsObject = this.props.currentWebsite.redirects;
    if (redirectsObject) {
      let displayRedirectItems = () => {
        let redirectItemsArray = redirectsObject.items;
        if (redirectItemsArray) {
          if (redirectItemsArray.length > 0) {
            const redirectItemsJSX = redirectsObject.items.map(
              (item: any, key: number) => {
                return (
                  <div className="website-redirects__items" key={item.id}>
                    <WebsiteRedirectItem
                      itemId={item.id}
                      connectedOn={item.connectedOn}
                      currentRedirectFromUrl={item.currentRedirectFromUrl}
                      currentRedirectDestinationUrl={
                        item.currentRedirectDestinationUrl
                      }
                      redirectUrlPlaceholder={this.redirectUrlPlaceholder}
                      websites={this.props.websites}
                      editRedirectItem={this.editRedirectItem}
                      deleteRedirectItem={this.deleteRedirectItem}
                    />
                  </div>
                );
              }
            );

            return redirectItemsJSX;
          } else {
            //if redirectsObject.items is empty
            return null;
          }
        } else {
          // is redirectsObject.items is not set
          return null;
        }
      };

      let redirectsObject = this.props.currentWebsite.redirects;
      let badgeCount = 0;
      let shouldDisplayCountBadge =
        redirectsObject !== undefined && redirectsObject.items !== undefined;
      if (shouldDisplayCountBadge) {
        badgeCount = this.props.currentWebsite.redirects.items.length;
      }

      return (
        <div className="settings-box__input website-redirects">
          <div className="settings-box__input_label">
            <div
              className="settings-box__input_info_text"
              style={{
                fontWeight: 600,
                marginLeft: 0,
                marginRight: 6,
              }}
            >
              Redirects
            </div>
            <div>
              <Tooltip
                title={
                  <div>
                    Redirect your visitors to a another page or website.
                    <br />
                    <br />
                    Please note redirects are cached by your browser. So after a
                    change a <i>previous</i> redirect will work for some time.{" "}
                    <br />
                    We recommend to use this service{" "}
                    <a
                      target="_blank"
                      style={{ color: "white", textDecoration: "underline" }}
                      href="https://httpstatus.io/"
                    >
                      httpstatus.io <Icon type={"link"} />
                    </a>{" "}
                    to check the redirects.
                  </div>
                }
              >
                <Icon style={{fontSize: 12}} type="info-circle" />
              </Tooltip>
            </div>
          </div>

          <div className="website-redirects__items_box">
            {displayRedirectItems()}
            <Button
              onClick={() => {
                this.addNewRedirectItem();
              }}
              loading={
                this.props.currentWebsite
                  .isWaitingForCommonWebsiteUpdateResponse
              }
              size={"small"}
              icon={"plus"}
            >
              Add new redirect
            </Button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default WebsiteRedirects;
