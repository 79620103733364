import React, { Component } from "react";
import { Switch, Tooltip } from "antd";

interface Props {
  websites: any;
  websitesPages: any;
  auth: any;

  currentWebsite: any;
  currentWebsitePage: any;

  publishPageToggleWaitingForServer: any;

  publishPage: any;
  isPublishDisabled: boolean;
  setUserStateData: any;
}
class PublishPageForm extends Component<Props> {
  handlePublishChange = (checked: boolean): void => {
    this.props.publishPage(
      checked,
      this.props.currentWebsitePage.url,
      this.props.currentWebsite
    );
  };

  render() {
    let isPagePublished = this.props.currentWebsitePage.is_published;

    return (
      <div>
        <Tooltip
          title={
            this.props.isPublishDisabled ? (
              <div>
                You need to{" "}
                <button
                  onClick={() => {
                    this.props.setUserStateData({
                      settings: "account__plan",
                    });
                  }}
                  className="content-input__tooltip-link"
                >
                  upgrade
                </button>{" "}
                to a paid plan to publish your page.
              </div>
            ) : null
          }
        >
          <Switch
            loading={this.props.websitesPages.isWaitingForPublishPageResponse}
            checked={this.props.isPublishDisabled ? false : isPagePublished}
            onChange={this.handlePublishChange}
            disabled={this.props.isPublishDisabled}
          />
        </Tooltip>  
      </div>
    );
  }
}

export default PublishPageForm;
