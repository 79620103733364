import axios, { AxiosResponse } from "axios";
import { apiUrlBase } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type ValidateDnsAsync = (payload: {
  accessToken: string;
  data: {
    domain: string;
    recordType: string;
    resolver?: string;
  };
}) => Promise<AxiosResponse<any>>;

export const validateDnsAsync: ValidateDnsAsync = ({ accessToken, data }) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiUrlBase}/api/v1/meta/get_dns_records?domain=${data.domain}&record_type=${data.recordType}&resolver=${data.resolver}`;
    axios
      .get(apiUrl, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
