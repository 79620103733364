import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {pictogramOptions} from "../../data/pictogramOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import Pictogram from "../component_molecules/Pictogram";
import WebsiteButton from "../component_molecules/WebsiteButton";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Links06 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Our main Products"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({
              isDefaultEmpty,
                subtitleText: ''
            }));
            this.props.saveContentInStore(this.generator.setUpFeatures({
              isDefaultEmpty,
                fieldTitle: {default: "Links"},
                label: {default: "Edit the links list:"},
                labelTooltip: {default: "Leave the title field blank to remove a link."},
                maxItems: 24,
                hasPictogram: true,
                hasSubtitle: true,
                hasText: false,
                hasHighlight: false,
                hasHighlightInfo: false,
                items: [
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Unicorn Platform',
                            subtitle: 'Ferox candidatus cito consumeres apolloniates est. Observare patienter ducunt ad fidelis glos.',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Try this", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline}),
                            pictogram: this.generator.createPictogramContentObject({
                                type: pictogramOptions.emoji.title,
                                emojiSrc: '1f984.svg'
                            })
                        }
                    ),
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Corporate Blog',
                            subtitle: 'Nunquam imperium adelphis. Fidelis, varius cedriums interdum transferre de nobilis, pius itineris tramitem.',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "About us", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline}),
                            pictogram: this.generator.createPictogramContentObject({
                                type: pictogramOptions.emoji.title,
                                emojiSrc: '26a1.svg'
                            })
                        }
                    ),
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Components Collection',
                            subtitle: 'Tus, hilotae, et turpis. Cum parma potus, omnes toruses fallere rusticus, grandis mensaes.',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Discover", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline}),
                            pictogram: this.generator.createPictogramContentObject({
                                type: '',
                            })
                        }
                    ),
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Integrated CRM',
                            subtitle: 'Abactuss velum in cirpi! Nunquam contactus torus. A falsis.',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Explore", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline}),
                            pictogram: this.generator.createPictogramContentObject({
                                type: '',
                            })
                        }
                    ),
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Templates Gallery',
                            subtitle: 'Rusticus ususs ducunt ad messor. Solitudos imitari crescere, tanquam pius bursa. Cum clinias unda, omnes deuses imperium gratis omnes.',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "See the Gallery", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline}),
                            pictogram: this.generator.createPictogramContentObject({
                                type: '',
                            })
                        }
                    ),
                    this.generator.createFeatureContentObject(
                        {
                            title: 'The Radar',
                            subtitle: 'Cum pulchritudine unda, omnes genetrixes dignus varius, bi-color verpaes. Raptus, grandis clabulares interdum imitari de brevis, fidelis urbs.',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Read review", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline}),
                            pictogram: this.generator.createPictogramContentObject({
                                type: '',
                            })
                        }
                    ),
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Support Hub',
                            subtitle: 'Cur coordinatae accelerare? Cur parma nocere? Ubi est audax barcas? Altus, clemens equisos sapienter attrahendam de festus, gratis mens.',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Get it", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline}),
                            pictogram: this.generator.createPictogramContentObject({
                                type: '',
                            })
                        }
                    ),
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Forms Builder',
                            subtitle: 'Ubi est bassus silva? Heu, abnoba! Heu. Cum calcaria volare, omnes advenaes consumere grandis, alter triticumes',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Read in blog", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline}),
                            pictogram: this.generator.createPictogramContentObject({
                                type: '',
                            })
                        }
                    ),


                    // The last ghost item which is used to add new items.
                    this.generator.createFeatureContentObject({
                        title: '',
                        text: '',
                        button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Send CV", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline}),
                        pictogram: this.generator.createPictogramContentObject({
                            type: '',
                        })
                    }),
                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the links list component to show more products of your company.",
                    default: "Add a CTA (call to action) to the bottom of the links list component to show more products of your company.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'See'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let features = this.props.componentItem.componentData.features.content.items;
        let feature1 = this.props.componentItem.componentData.features.content.items[0];
        let feature2 = this.props.componentItem.componentData.features.content.items[1];

        return (
            <section>
                <div className="links-06">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container links-06__container">
                        <ul className="links-06__list links-06__list--bigger">
                            {feature1 && feature1.title !=='' && <li className={"sh-3 card-container links-06__article links-06__article--white-bg "}>
                                {feature1.pictogram.type && feature1.pictogram.type !== '' &&
								<div className="links-06__logo">
                    <Pictogram 
                      pictogramObject={feature1.pictogram}
                      className=''
                    />
								</div>
                                }

								<h3 className="def-20 title-text--inner links-06__title">{feature1.title}</h3>
								<div className="content-text links-06__text" dangerouslySetInnerHTML={{__html: feature1.subtitle}}/>
								<div className="links-06__link link feature__button_box">
                                    {feature1.button.title !== '' && feature1.button.href !== '' && <WebsiteButton config={{
                                        buttonStyleType: feature1.button.settings.appearance.styleType,
                                        buttonColor: feature1.button.settings.appearance.color,
                                        buttonType: linkOrButtonOptions.link,
                                        buttonTitle: feature1.button.title,
                                        buttonHref: feature1.button.href,
                                        buttonIsTargetBlank: feature1.button.isTargetBlank,
                                        buttonMobileAppStoreType: feature1.button.settings.appearance.mobileAppStoreType,
                                        pillPrefix: feature1.button.pillPrefix,
                                        pillColor: feature1.button.settings.appearance.pillColor,
                                        ghostColor: feature1.button.settings.appearance.ghostColor,
                                    }} />}
								</div>
							</li>}

                            {feature2 && feature2.title !=='' && <li className={"card-container links-06__article "}>
                                {feature2.pictogram.type && feature2.pictogram.type !== '' &&
								<div className="links-06__logo">
                                    <Pictogram 
                                      pictogramObject={feature2.pictogram}
                                      className=''
                                    />
								</div>
                                }

								<h3 className={"def-20 title-text--inner links-06__title " + (this.props.isDarkBg ? 'text-white' : '')}>{feature2.title}</h3>
								<div className={"content-text links-06__text " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: feature2.subtitle}}/>
								<div className="links-06__link link feature__button_box">
                                    {feature2.button.title !== '' && feature2.button.href !== '' && <WebsiteButton config={{
                                        buttonStyleType: feature2.button.settings.appearance.styleType,
                                        buttonColor: feature2.button.settings.appearance.color,
                                        buttonType: linkOrButtonOptions.link,
                                        buttonTitle: feature2.button.title,
                                        buttonHref: feature2.button.href,
                                        buttonIsTargetBlank: feature2.button.isTargetBlank,
                                        buttonMobileAppStoreType: feature2.button.settings.appearance.mobileAppStoreType,
                                        pillPrefix: feature2.button.pillPrefix,
                                        pillColor: feature2.button.settings.appearance.pillColor,
                                        ghostColor: feature2.button.settings.appearance.ghostColor,
                                    }} />}
								</div>
							</li>}
                        </ul>

                        <ul className="links-06__list links-06__list--smaller">
                            {features && features.map((item:any, key:number) => {
                                /* key > 1 because the first 2 items are displayed in another <ul>*/
                                // Item #4 (index or key === 3) has gray BG and therefore has always black text
                                if(item.title && item.title !== '' && key > 1){
                                    return (
                                        <li className={"card-container links-06__article " + (key === 3 ? ' links-06__article--gray-bg ' : '')} key={'links-06-' + key}>
                                            {item.pictogram.type && item.pictogram.type !== '' &&
											<div className="links-06__logo links-06__logo--smaller">
                                                <Pictogram 
                                                  pictogramObject={item.pictogram}
                                                  className=''
                                                />
											</div>
                                            }
                                            <h3 className={"def-20 title-text--inner links-06__title " + ((this.props.isDarkBg && key !== 3) ? 'text-white' : '')}>{item.title}</h3>
                                            <div className={"content-text links-06__text " + ((this.props.isDarkBg && key !== 3) ? 'text-white' : '')}>{item.subtitle}
                                            </div>
                                            <div className="links-06__link link feature__button_box">
                                                {item.button.title !== '' && item.button.href !== '' && <WebsiteButton config={{
                                                    buttonStyleType: item.button.settings.appearance.styleType,
                                                    buttonColor: item.button.settings.appearance.color,
                                                    buttonType: linkOrButtonOptions.link,
                                                    buttonTitle: item.button.title,
                                                    buttonHref: item.button.href,
                                                    buttonIsTargetBlank: item.button.isTargetBlank,
                                                    buttonMobileAppStoreType: item.button.settings.appearance.mobileAppStoreType,
                                                    pillPrefix: item.button.pillPrefix,
                                                    pillColor: item.button.settings.appearance.pillColor,
                                                    ghostColor: item.button.settings.appearance.ghostColor,
                                                }} />}
                                            </div>
                                        </li>
                                    )
                                }else{
                                    return (null);
                                }
                            })}
                        </ul>


                    </div>

                    <div className="bottom_cta">
                        {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                    </div>
                </div>
            </section>
        )
    }
}

export default Links06;
