import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteButton from "../component_molecules/WebsiteButton";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Posts02 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({titleText: "",isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({subtitleText: '',isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpPosts({
              isDefaultEmpty,
                maxItems: 36,
                hasButton: true,
                buttonTypes: [buttonStyles.ghost.typeTitle],
                hasHighlight: true,

                items: [
                    this.generator.createPostsContentObject({
                        title: "Attractive Design Secrets",
                        subtitle: 'Lixa, bromium, et messor. Cum diatria ire, omnes mineralises imperium camerarius, domesticus orexises.',
                        isHighlighted: true,
                        thumb: this.generator.createGraphicsContentObject({
                            imageUrl: DEFAULT_IMAGES_CDN.PHOTO_04,
                            width: 3201,
                            height: 1799,
                        }),
                        button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "About us", buttonHref: '#', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline, buttonIsTargetBlank: true}),
                    }),

                    this.generator.createPostsContentObject({
                        title: "How Not to Get Lost in the World of Information",
                        subtitle: 'Pol, a bene olla. Cum nuptia cantare, omnes lunaes attrahendam camerarius, albus buxumes.',
                        thumb: this.generator.createGraphicsContentObject({
                            imageUrl: DEFAULT_IMAGES_CDN.PHOTO_05,
                            width: 5476,
                            height: 3656
                        }),
                        button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Read here", buttonHref: '#', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline, buttonIsTargetBlank: true}),
                    }),
                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the posts component to invite users to read your blog.",
                    default: "Add a button to the bottom of the posts component to invite users to read your blog.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Read more'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let posts = this.props.componentItem.componentData.posts.content.items;

        return (
            <section>

                <div className="posts-02">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container container--mid">
                        <div className="posts-02__wrapper">
                            {posts && posts.map((item:any, key:number) => {
                                if(item !== undefined){
                                    let hasImage = item.thumb && item.thumb.image && item.thumb.image.url !== undefined && item.thumb.image.url !== '';
                                    return (
                                        <div className={"card-container posts-02__item " + (item.isHighlighted ? ' posts-02__item--accent ' : ' ')} key={'posts-02-' + key}>
                                            <div className={"posts-02__text " + (hasImage === false ? ' posts-02__text--full ' : ' ')}>
                                                <h3 className={"def-18_72 title-text--inner posts-02__title " + ((item.isHighlighted === false && this.props.isDarkBg) ? ' text-white ' : ' ')}>{item.title}</h3>
                                                <div className={"content-text def-14 posts-02__info " + ((item.isHighlighted === false && this.props.isDarkBg) ? ' text-white ' : ' ')}>{item.subtitle}</div>
                                                {item.button && item.button.title !== '' && <div className="feature__button_box">
                                                    <WebsiteButton config={{
                                                        buttonStyleType: item.button.settings.appearance.styleType,
                                                        buttonColor: item.button.settings.appearance.color,
                                                        buttonType: linkOrButtonOptions.link,
                                                        buttonTitle: item.button.title,
                                                        buttonHref: item.button.href,
                                                        buttonIsTargetBlank: item.button.isTargetBlank,
                                                        buttonMobileAppStoreType: item.button.settings.appearance.mobileAppStoreType,
                                                        pillPrefix: item.button.pillPrefix,
                                                        pillColor: item.button.settings.appearance.pillColor,
                                                        ghostColor: item.button.settings.appearance.ghostColor,
                                                    }} />
                                                </div>}
                                            </div>

                                            {hasImage && <div className="posts-02__img_box"><img className="posts-02__img" src={formatImageUrl(item.thumb.image.url)} alt={item.thumb.image.alt}/></div>}
                                        </div>
                                    )
                                }else{
                                    return (null);
                                }
                            })}
                        </div>
                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Posts02;
