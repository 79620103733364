import React, { useEffect, useMemo, useRef, useState } from "react";
import DebouncedInput from "../../DebouncedInput";
import ContentInputLabel from "../../ui_components/ContentInputLabel";
import { Alert, Button, Icon, Select } from "antd";
import _ from "lodash";
import axios from "axios";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../../store/websites/thunks";
import {
  ChangeWebsitesStateGlobalData,
  GetCurrentStoreData,
  WebsitesState,
} from "../../../store/websites/types";
import { WebsitePagesState } from "../../../store/websitePages/types";
import { apiUrlBase } from "../../../data/urls";
import { AuthState } from "../../../store/auth/types";
import returnAuthHeaderForAJAX from "../../../helpers/auth/returnAuthHeaderForAJAX";
import { CmsType } from "../../../enums/enums";
import { changeWebsitesStateGlobalData } from "../../../store/websites/actions";
import "./CMSMenu.scss";
import classNames from "classnames";
import { ReactComponent as CmsIcon } from "../../../img/icons/cms-outline.svg";
import { UserState } from "../../../store/user/types";
import { formatImageUrl } from "../../../helpers/strings/formatImageUrl";
import { getPageCollectionId } from "../../../helpers/getPageCollectionId";

interface Props {
  cmsType?: CmsType;
  customApiUrl?: string;
  unicornCollectionId?: string;
  cmsUrl?: string;
  onSelectCollection?: (value, name) => void;
  onChangeCustomApiUrl?: (value) => void;
  onChangeGoogleSheetsUrl?: (value) => void;
  onChangeCmsType?: (value) => void;
  hasUnsavedChanges?: boolean;
  onSaveChanges?: () => void;
  hideLabels?: boolean;
  allowCmsClear?: boolean;
  className?: string;
  inputDebounceTime?: number;
  saveButtonLoading?: boolean;
  customGoogleSheetsInputJsx?: JSX.Element;
  menuType?: string;

  websites: WebsitesState;
  auth: AuthState;
  websitesPages: WebsitePagesState;
  user: UserState;
  getCurrentStoreData: GetCurrentStoreData;
  changeWebsitesStateGlobalData: ChangeWebsitesStateGlobalData;
}

interface UnicornCmsProps extends Props {}
const UnicornCms = (props: UnicornCmsProps): JSX.Element => {
  const {
    unicornCollectionId,
    getCurrentStoreData,
    auth,
    changeWebsitesStateGlobalData,
    onSelectCollection,
    hideLabels,
    websitesPages,
    user,
    menuType,
  } = props;
  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentWebsite, currentWebsitePage } = getCurrentStoreData();
  const containerRef = useRef(null);

  const fetchCollections = () => {
    const websiteId =
      process.env.NODE_ENV === "development" ? 866 : currentWebsite.id;
    const url = `${apiUrlBase}/api/v1/websites/list_collections?website_id=${websiteId}`;
    const accessToken = auth.accessToken;
    axios
      .get(url, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        setCollections(Array.isArray(response.data) ? response.data : []);
      })
      .catch((error) => {
        console.log("error: ", error);
        console.log("error.response: ", _.get(error, "response"));
        setError("Error: Couldn't fetch collections.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  const selectOptions = collections.map((collection) => (
    <Select.Option value={collection.id} key={collection.id}>
      <span style={{ display: "flex", alignItems: "center", gap: 9 }}>
        <CmsIcon width="14px" height="14px" />
        {collection.name}
      </span>
    </Select.Option>
  ));

  const pageCollectionId = getPageCollectionId(currentWebsitePage);
  const pageCollection = !pageCollectionId
    ? null
    : collections.find((c) => {
        return c.id === pageCollectionId;
      });

  const isRelatedItemsSelected =
    !!pageCollectionId && unicornCollectionId === "default-related-items";

  const collectionIdExists =
    !!collections.find((c) => c.id === unicornCollectionId) ||
    isRelatedItemsSelected;

  return (
    <div ref={containerRef}>
      {!hideLabels && <ContentInputLabel>Collection</ContentInputLabel>}
      {isLoading && (
        <div>
          <Icon type="loading" style={{ color: "#1890FF", marginRight: 10 }} />
          Loading collections...
        </div>
      )}
      {!!error && (
        <Alert message={error} type="error" showIcon style={{ width: 300 }} />
      )}
      {!isLoading && !error && (
        <Select
          style={{ width: 230 }}
          disabled={websitesPages.isWaitingForUpdatePageSourceApiResponse}
          value={collectionIdExists ? unicornCollectionId : undefined}
          onChange={(v) => {
            const collection = collections.find((c) => c.id === v);
            const n = collection ? collection.name : "";
            onSelectCollection(v, n);
          }}
          placeholder="Select collection"
          getPopupContainer={() => containerRef.current}
          showSearch
          className="is-text-cursor"
          filterOption={(input, option) => {
            return (
              // @ts-ignore
              (option.props.children.props.children[1] || "")
                .toLowerCase()
                .indexOf((input || "").toLowerCase()) >= 0
            );
          }}
        >
          {!!pageCollectionId && menuType === "component" && (
            <Select.Option value="default-related-items">
              <span style={{ display: "flex", alignItems: "center", gap: 9 }}>
                <Icon type="apartment" style={{ fontSize: 14 }} />
                Related items
              </span>
            </Select.Option>
          )}
          {selectOptions}
        </Select>
      )}
      {!!isRelatedItemsSelected &&
        menuType === "component" &&
        !!pageCollection &&
        !isLoading && (
          <Alert
            style={{ marginTop: 12, width: 384 }}
            message={
              <div>
                This component will dynamically display related items for{" "}
                <b>{pageCollection.name}</b> collection. Open the page in live
                mode to see it in action.
              </div>
            }
            type="info"
          />
        )}
    </div>
  );
};

interface CustomApiProps extends Props {}
const CustomApi = (props: CustomApiProps): JSX.Element => {
  const {
    customApiUrl,
    onChangeCustomApiUrl,
    hideLabels,
    websitesPages,
    inputDebounceTime,
    onSaveChanges,
  } = props;

  const onPressEnter = () => {
    if (onSaveChanges) {
      onSaveChanges();
    }
  };

  return (
    <div>
      {!hideLabels && (
        <ContentInputLabel title="The URL for the API endpoint, which returns data in CSV or JSON format.">
          Endpoint URL
        </ContentInputLabel>
      )}
      <DebouncedInput
        onChange={onChangeCustomApiUrl}
        placeholder="https://jsonplaceholder.typicode.com/posts"
        defaultValue={customApiUrl}
        disabled={websitesPages.isWaitingForUpdatePageSourceApiResponse}
        debounceDelay={inputDebounceTime || 500}
        onPressEnter={onPressEnter}
      />
    </div>
  );
};

interface GoogleSheetsProps extends Props {}
const GoogleSheets = (props: GoogleSheetsProps): JSX.Element => {
  const {
    cmsUrl,
    onChangeGoogleSheetsUrl,
    hideLabels,
    websitesPages,
    inputDebounceTime,
    onSaveChanges,
  } = props;

  const onPressEnter = () => {
    if (onSaveChanges) {
      onSaveChanges();
    }
  };

  return (
    <div>
      {!hideLabels && (
        <ContentInputLabel title="URL of the Google Sheets document in CSV format.">
          Google Sheets URL
        </ContentInputLabel>
      )}
      <DebouncedInput
        onChange={onChangeGoogleSheetsUrl}
        placeholder="https://docs.google.com/spreadsheets/d/e/2PACX-1vRKapXyifgCbzW_cAgpYEA78r2r7KNXPKueel8ZpcA1gpzzCV7sBFTZktDnLm9hDKG1kcKZM7NTrtLc/pub?output=csv"
        defaultValue={cmsUrl}
        disabled={websitesPages.isWaitingForUpdatePageSourceApiResponse}
        debounceDelay={inputDebounceTime || 500}
        onPressEnter={onPressEnter}
      />
      {!hideLabels && (
        <div style={{ marginTop: 7 }}>
          <ContentInputLabel noMargin>
            Learn more in{" "}
            <a
              href="https://help.unicornplatform.com/en/article/how-to-configure-directory-component-directory-page-1fvlq92/"
              className="underlined_link"
              target="_blank"
            >
              our guide
            </a>
            .
          </ContentInputLabel>
        </div>
      )}
    </div>
  );
};

interface CMSSelectorProps extends Props {}
const CMSSelector = (props: CMSSelectorProps): JSX.Element => {
  const {
    cmsType,
    onChangeCmsType,
    hideLabels,
    allowCmsClear,
    websitesPages,
    user,
  } = props;
  const containerRef = useRef(null);

  return (
    <div ref={containerRef}>
      {!hideLabels && <ContentInputLabel>Select CMS</ContentInputLabel>}
      <Select
        defaultValue={cmsType || "none"}
        onChange={onChangeCmsType}
        getPopupContainer={() => containerRef.current}
        placeholder="Select CMS"
        disabled={websitesPages.isWaitingForUpdatePageSourceApiResponse}
        style={{ width: 230 }}
      >
        {allowCmsClear && (
          <Select.Option value="none">
            <span style={{ display: "flex", alignItems: "center", gap: 9 }}>
              No source
            </span>
          </Select.Option>
        )}
        <Select.Option value="unicorn">
          <span style={{ display: "flex", alignItems: "center", gap: 9 }}>
            <img
              src={formatImageUrl(
                "https://unicorn-cdn.b-cdn.net/9858a4a2-398d-4609-b08c-82c4dde7569e/unicorn-platform-logo.svg"
              )}
              height="14px"
              width="14px"
              style={{ objectFit: "contain", opacity: 1 }}
            />
            Unicorn Platform CMS
          </span>
        </Select.Option>
        <Select.Option value="googleSheets">
          <span style={{ display: "flex", alignItems: "center", gap: 9 }}>
            <img
              src="https://dvzvtsvyecfyp.cloudfront.net/static/img/logos/companies/google-sheets-small.svg"
              height="14px"
              width="14px"
              style={{ objectFit: "contain" }}
            />
            Google Sheets
          </span>
        </Select.Option>

        <Select.Option value="custom">
          <span style={{ display: "flex", alignItems: "center", gap: 9 }}>
            <Icon type="api" style={{ width: 14 }} />
            Custom API
          </span>
        </Select.Option>
      </Select>
    </div>
  );
};

const CMSMenu = (props: Props): JSX.Element => {
  const {
    hasUnsavedChanges,
    onSaveChanges,
    className,
    cmsType,
    onSelectCollection,
    onChangeCustomApiUrl,
    onChangeGoogleSheetsUrl,
    onChangeCmsType,
    saveButtonLoading,
    customGoogleSheetsInputJsx,
    websites,
  } = props;

  const cmsMap = {
    [CmsType.UNICORN]: !!onSelectCollection && (
      <UnicornCms key={websites.cmsRerenderKey} {...props} />
    ),
    [CmsType.GOOGLE_SHEETS]: !!customGoogleSheetsInputJsx
      ? customGoogleSheetsInputJsx
      : !!onChangeGoogleSheetsUrl && <GoogleSheets {...props} />,
    [CmsType.CUSTOM]: !!onChangeCustomApiUrl && <CustomApi {...props} />,
  };

  return (
    <div
      className={classNames("cms-menu", {
        [className]: !!className,
      })}
    >
      {!!onChangeCmsType && <CMSSelector {...props} />}
      {cmsMap[cmsType]}
      {hasUnsavedChanges && !!onSaveChanges && (
        <div>
          <Button
            icon="save"
            type="primary"
            onClick={onSaveChanges}
            loading={saveButtonLoading}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    websitesPages: state.websitesPages,
    auth: state.auth,
    websites: state.websites,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  getCurrentStoreData,
  changeWebsitesStateGlobalData,
})(CMSMenu);
