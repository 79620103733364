import React, {Component} from "react";
import {Menu, Dropdown, Button} from "antd";
import './HelpButton.css'

import {openChatWidget} from "../../helpers/editor/openChatWidget";
import {urls} from "../../data/urls";
import Icon from "antd/lib/icon";

import VersionInfo from './VersionInfo';

const ButtonGroup = Button.Group;

interface Props {
}

class HelpButton extends Component<Props> {
    state = {
        isChatLoading: false,
    };

    openChat() {
        this.setState({
            isChatLoading: true,
        });
        (window as any).$crisp.push(["on", "chat:opened", () => {
            this.setState({
                isChatLoading: false,
            });
        }]);
        // (window as any).Intercom('onShow', () => {
        //     this.setState({
        //         isChatLoading: false,
        //     });
        // });

        openChatWidget();
    }

    render() {
        const menu = (
            <Menu>
                <Menu.Item>
                    <button
                        className="help-button__action"
                        onClick={() => {
                            this.openChat();
                        }}>Chat with a human <Icon type="smile" theme="twoTone"/>
                        <span className="help-button__info">Average response time is 40 min</span>
                    </button>
                </Menu.Item>
                <Menu.Item>
                    <a
                        className="help-button__action"
                        target="_blank" rel="noopener noreferrer" href={urls.help}>
                        Discover Help Center <Icon type="coffee" style={{color: "#795548"}}/>

                        <span className="help-button__info">Get the answer now</span>
                    </a>
                </Menu.Item>
                <Menu.Divider/>
                <VersionInfo />
                {/*<Menu.Item>*/}
                {/*<ButtonGroup className="help-button__icons">*/}
                {/*<Button shape="circle" size="small" target="_blank" href={urls.facebook} icon="facebook" />*/}
                {/*<Button shape="circle" size="small" target="_blank" href={urls.twitter} icon="twitter" />*/}
                {/*<Button shape="circle" size="small" target="_blank" href={urls.instagram} icon="instagram" />*/}
                {/*</ButtonGroup>*/}
                {/*</Menu.Item>*/}
            </Menu>
        );

        return (
            <Dropdown overlay={menu} placement="topRight" trigger={['click']} overlayStyle={{zIndex: 201000000, position: "fixed"}}>
                <Button
                    className="help-button"
                    icon={this.state.isChatLoading ? 'loading' : "question"}
                    shape="circle"
                    htmlType="button"/>
            </Dropdown>
        );

    }
}

export default HelpButton;
