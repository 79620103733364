import _ from "lodash";
import { getCurrentStoreData } from "../websites/thunks";
import isWebsiteSharedWithCurrentUser from "../../helpers/shareAccess/isWebsiteSharedWithCurrentUser";

export const isUserFreeNew = () => {
  return (dispatch, getState) => {
    const { currentWebsite } = dispatch(getCurrentStoreData());
    const { user } = getState();
    const { sharedAccesses } = getState();
    if (user.isPro) {
      return false;
    }
    if (!_.get(user, "data.additional_info.has_new_publish_rule", false)) {
      return false;
    }
    if (
      isWebsiteSharedWithCurrentUser(
        sharedAccesses,
        currentWebsite.id,
        user.data.email
      ).isTrue
    ) {
      return false;
    }
    return true;
  }
}
