import React, { useEffect, useState } from "react";
import UpdateCustomDomainForm from "./UpdateCustomDomainForm";
import { GetCurrentStoreData, WebsitesState } from "../../store/websites/types";
import { AuthState } from "../../store/auth/types";
import { UserState } from "../../store/user/types";
import { connect } from "react-redux";
import {
  customDomainUpdateErrorMessage,
  customDomainUpdateToggleInvalidAttempt,
  customDomainUpdateWaitingForServer,
  sslToggleActivationWaitingForServer,
  toggleCloudflareProtectionWaitingForServer,
} from "../../store/websites/actions";
import { Alert, Button, Icon, Steps } from "antd";
import "./CustomDomainSettings.css";
import DnsRecordsTable from "./DnsRecordsTable";
import { ActiveMenu } from "./SettingsDrawerContent";
import _ from "lodash";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { urls } from "../../data/urls";
import { MetaState } from "../../store/meta/types";
import addNoCacheParamToURL from "../../helpers/editor/addNoCacheParamToURL";
import { getSubdomain } from "../../helpers/custom_domain/getSubdomain";
import TransferDomainMenu from "./TransferDomainMenu";
import { openChatWidget } from "../../helpers/editor/openChatWidget";
import PingDomainMenu from "./PingDomainMenu";

export enum SetupVersions {
  LEGACY = "legacy",
  APEX_TO_WWW = "apex_to_www",
}

const SaveButton = (props: {
  customDomainInputValue: string;
  websites: WebsitesState;
  currentWebsite: any;
}): JSX.Element => {
  const { customDomainInputValue, websites, currentWebsite } = props;

  if (
    currentWebsite.custom_domain === customDomainInputValue &&
    !customDomainInputValue
  ) {
    return null;
  }

  return (
    <Button
      type="primary"
      htmlType="submit"
      form="custom_domain_form"
      loading={websites.isWaitingForCustomDomainUpdateResponse}
      hidden={currentWebsite.custom_domain === customDomainInputValue}
      icon="save"
    />
  );
};

interface Props {
  websites: WebsitesState;
  auth: AuthState;
  user: UserState;
  meta: MetaState;
  customDomainUpdateToggleInvalidAttempt: (payload: boolean) => void;
  customDomainUpdateErrorMessage: (payload: string) => void;
  customDomainUpdateWaitingForServer: (payload: boolean) => void;

  history: any;
  currentWebsite: any;
  saveWebsiteDataInStore: (payload: any, subdomain?: string) => void;
  doesCurrentUserHaveAccessToPaidFeatures: boolean;
  toggleCloudflareProtectionWaitingForServer: (payload: boolean) => void;
  sslToggleActivationWaitingForServer: (payload: boolean) => void;
  setActiveMenu: React.Dispatch<React.SetStateAction<ActiveMenu>>;
  getCurrentStoreData: GetCurrentStoreData;
}
const CustomDomainSettingsNew = (props: Props): JSX.Element => {
  const {
    currentWebsite,
    websites,
    auth,
    user,
    history,
    customDomainUpdateToggleInvalidAttempt,
    customDomainUpdateErrorMessage,
    customDomainUpdateWaitingForServer,
    saveWebsiteDataInStore,
    doesCurrentUserHaveAccessToPaidFeatures,
    toggleCloudflareProtectionWaitingForServer,
    sslToggleActivationWaitingForServer,
    meta,
  } = props;

  const [isTransferSuccess, setIsTransferSuccess] = useState<boolean>(false);

  const [customDomainInputValue, setCustomDomainInputValue] = useState<string>(
    currentWebsite.custom_domain
  );

  const clearUpdateCustomDomainInput = () => {
    customDomainUpdateToggleInvalidAttempt(false);
    customDomainUpdateErrorMessage(undefined);
  };

  useEffect(() => {
    clearUpdateCustomDomainInput();
  }, []);

  useEffect(() => {
    setCustomDomainInputValue(currentWebsite.custom_domain);
  }, [currentWebsite.id]);

  const handleUpgradeToUnlock = (e: any): void => {
    props.setActiveMenu("account__plan");
  };

  if (!doesCurrentUserHaveAccessToPaidFeatures) {
    return (
      <div className="custom-domain-settings">
        <p className="custom-domain-settings__subtitle">
          Please upgrade to a paid plan to connect custom domain.
        </p>
        <Button
          onClick={handleUpgradeToUnlock}
          icon="star"
          type="primary"
          size="large"
          htmlType="button"
          style={{ marginTop: 10 }}
        >
          Upgrade
        </Button>
      </div>
    );
  }
  
  const isLegacy = currentWebsite.domain_setup_version === SetupVersions.LEGACY || currentWebsite.domain_setup_version === "1"
  const isApexToWww = currentWebsite.domain_setup_version === SetupVersions.APEX_TO_WWW || currentWebsite.domain_setup_version === "2"

  const isPing = _.get(currentWebsite, "domain_data.is_ping", false)

  let wwwString = "";
  if (
    !getSubdomain(currentWebsite.custom_domain) &&
    isApexToWww
  ) {
    wwwString = "www.";
  }

  const isTransferMenuVisible = !!currentWebsite.custom_domain && (isLegacy || _.get(currentWebsite, "domain_data.is_transfer", false))

  return (
    <div className="custom-domain-settings">
      <div className="settings-box__input">
        <p className="custom-domain-settings__subtitle">
          Enter a domain name that you own:
        </p>
        <div className="custom-domain-settings__content">
          <UpdateCustomDomainForm
            currentWebsiteCustomDomain={currentWebsite.custom_domain}
            currentWebsiteSubdomain={currentWebsite.subdomain}
            websites={websites}
            clearUpdateCustomDomainInput={clearUpdateCustomDomainInput}
            auth={auth}
            user={user}
            history={history}
            customDomainUpdateWaitingForServer={
              customDomainUpdateWaitingForServer
            }
            customDomainUpdateToggleInvalidAttempt={
              customDomainUpdateToggleInvalidAttempt
            }
            customDomainUpdateErrorMessage={customDomainUpdateErrorMessage}
            saveWebsiteDataInStore={saveWebsiteDataInStore}
            doesCurrentUserHaveAccessToPaidFeatures={
              doesCurrentUserHaveAccessToPaidFeatures
            }
            currentWebsite={currentWebsite}
            customDomainInputValue={customDomainInputValue}
            setCustomDomainInputValue={setCustomDomainInputValue}
            setIsTransferSuccess={setIsTransferSuccess}
          />
          <SaveButton
            customDomainInputValue={customDomainInputValue}
            websites={websites}
            currentWebsite={currentWebsite}
          />
        </div>
        {isPing && (
          <PingDomainMenu
            setIsTransferSuccess={setIsTransferSuccess}
            currentWebsite={currentWebsite}
            auth={auth}
            saveWebsiteDataInStore={saveWebsiteDataInStore}
          />
        )}
        {isTransferSuccess && (
          <div style={{ marginTop: 20 }}>
            <Alert
              message={
                <div>
                  Upgrade complete{" "}
                  <Icon
                    type="check-circle"
                    style={{ color: "#52c41a", marginLeft: 4, fontSize: 13 }}
                  />
                </div>
              }
              description={
                <div>
                  <p>
                    Your domain has been successfully upgraded. You can access
                    your website at{" "}
                    <a
                      className="underlined_link"
                      target={"_blank"}
                      href={`https://${wwwString}${currentWebsite.custom_domain}`}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          addNoCacheParamToURL(
                            `https://${wwwString}${currentWebsite.custom_domain}`
                          )
                        );
                      }}
                    >
                      https://{wwwString}
                      {currentWebsite.custom_domain}
                    </a>
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    If you have any questions, please{" "}
                    <button
                      onClick={openChatWidget}
                      className="underlined_link"
                      style={{ cursor: "pointer", color: "#1890ff" }}
                    >
                      chat with us
                    </button>
                  </p>
                </div>
              }
              type="success"
            />
          </div>
        )}
        {isTransferMenuVisible && (
            <TransferDomainMenu
              setIsTransferSuccess={setIsTransferSuccess}
              saveWebsiteDataInStore={saveWebsiteDataInStore}
            />
          )}
        {!!currentWebsite.custom_domain &&
          !isTransferSuccess &&
          !isLegacy &&
          !isPing &&
          !_.get(currentWebsite, "domain_data.is_transfer", false) && (
            <div style={{ marginTop: 30 }}>
              <p className="custom-domain-settings__subtitle">
                Set up DNS records with the following values (
                <a
                  className="underlined_link"
                  target={"_blank"}
                  href={urls.guides.connectCustomDomain}
                >
                  learn more
                </a>
                ):
              </p>
              <div style={{ marginTop: 10 }}>
                <DnsRecordsTable currentWebsite={currentWebsite} meta={meta} />
              </div>
              <p
                className="custom-domain-settings__subtitle"
                style={{ marginTop: 10 }}
              >
                Once you've added the DNS records, please allow up to an hour
                for the domain to initialize.
              </p>
              <p
                className="custom-domain-settings__subtitle"
                style={{
                  marginTop: 30,
                  maxWidth: "100%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                Your website will be available at{" "}
                <a
                  className="underlined_link"
                  target={"_blank"}
                  href={`https://${wwwString}${currentWebsite.custom_domain}`}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      addNoCacheParamToURL(
                        `https://${wwwString}${currentWebsite.custom_domain}`
                      )
                    );
                  }}
                >
                  https://{wwwString}
                  {currentWebsite.custom_domain}
                </a>
              </p>
            </div>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    websites: state.websites,
    auth: state.auth,
    user: state.user,
    meta: state.meta,
  };
};
const mapActionsToProps = {
  customDomainUpdateToggleInvalidAttempt,
  customDomainUpdateErrorMessage,
  customDomainUpdateWaitingForServer,
  toggleCloudflareProtectionWaitingForServer,
  sslToggleActivationWaitingForServer,
  getCurrentStoreData,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(CustomDomainSettingsNew);
