import React, { Component } from "react";
import {
  Checkbox,
  Divider,
  Icon,
  Popconfirm,
  Popover,
  Switch,
  Tooltip,
} from "antd";
import { projectTitle } from "../../data/constants";
import "./WebsiteSEOsettings.css";
import { api } from "../../data/urls";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { openNotification } from "../../helpers/openNotification";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import WebsiteRedirects from "./WebsiteRedirects";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import WebsiteLanguageSettings from "./WebsiteLanguageSettings";
import _ from "lodash";

interface Props {
  auth: any;
  user: any;
  websites: any;
  currentWebsite: any;
  fullWebsiteUrl: any;
  toggleWaitingForCommonWebsiteUpdate: any;
  saveWebsiteDataInStore: any;
  toggleWaitingForWebsiteLanguagesUpdate: any;
}

class WebsiteSEOsettings extends Component<Props> {
  confirmDisallow = (data) => {
    this.props.toggleWaitingForCommonWebsiteUpdate(true);

    let accessToken = this.props.auth.accessToken;

    let apiUrl =
      api.websites.updateWebsite.prefix +
      this.props.currentWebsite.subdomain +
      api.websites.updateWebsite.postfix;
    axios
      .patch(apiUrl, data, { ...returnAuthHeaderForAJAX(accessToken) })
      .then(() => {
        const dataToStore = {
          data: {
            ...this.props.currentWebsite,
            ...data,
          },
        };
        this.props.saveWebsiteDataInStore(
          dataToStore,
          this.props.currentWebsite.subdomain
        );
        // Tell a user that the operation is successful.
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          handleBadWebsiteError(errorData);
          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleWaitingForCommonWebsiteUpdate(false);
      });
  };
  render() {
    let isWaitingForServerResponse =
      this.props.websites.isWaitingForCommonWebsiteUpdateResponse;

    let isDisallowAllActive =
      this.props.currentWebsite.is_disallow_crawling_bots;

    let confirmDisallowButtonText = "Yes, disallow all";
    let confirmDisallowDetailsMessage =
      "Are you sure you want to disallow all bots (including search engines) to crawl your website? This may damage your SEO positions.";
    if (isDisallowAllActive) {
      confirmDisallowButtonText = "Allow all robots";
      confirmDisallowDetailsMessage =
        "Do you want to allow robots to crawl your website?";
    }
    const seoData = this.props.currentWebsite.seo_data || {};

    return (
      <div>
        <div className="account_fields__block">
          <div className="settings-box__input_label">
            <div
              className="settings-box__input_info_text"
              style={{
                fontWeight: 600,
                marginLeft: 0,
                marginRight: 6,
              }}
            >
              Boost performance
            </div>
            <div>
              <Tooltip
                title={
                  <div>
                    Improves your website's performance score.
                    <br />
                    <br />
                    May affect existing custom code on this website. Please make
                    sure to test it after enabling any of these settings.
                  </div>
                }
              >
                <Icon style={{ fontSize: 12 }} type="info-circle" />
              </Tooltip>
            </div>
          </div>
          <div className="account_fields__rows">
            <div
              className="account_fields__row"
              style={{ marginBottom: "20px" }}
            >
              <div>
                <div className="account_fields__title">Delayed JavaScript</div>
                <div className="account_fields__value">
                  <div className="account_fields__value_wrapper">
                    Prevents render blocking. Some elements may become
                    unresponsive for a few seconds.
                  </div>
                </div>
              </div>
              <div className="account_fields__action">
                <Switch
                  checked={seoData.isJsDelayed}
                  loading={isWaitingForServerResponse}
                  onChange={() => {
                    this.confirmDisallow({
                      seo_data: {
                        ...seoData,
                        isJsDelayed: !seoData.isJsDelayed,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div
              className="account_fields__row"
              style={{ marginBottom: "20px" }}
            >
              <div>
                <div className="account_fields__title">Inline CSS</div>
                <div className="account_fields__value">
                  <div className="account_fields__value_wrapper">
                    Load CSS inline instead of in a separate file.
                  </div>
                </div>
              </div>
              <div className="account_fields__action">
                <Switch
                  checked={seoData.isCssInline}
                  loading={isWaitingForServerResponse}
                  onChange={() => {
                    this.confirmDisallow({
                      seo_data: {
                        ...seoData,
                        isCssInline: !seoData.isCssInline,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div
              className="account_fields__row"
              style={{ marginBottom: "20px" }}
            >
              <div>
                <div className="account_fields__title">Async fonts</div>
                <div className="account_fields__value">
                  <div className="account_fields__value_wrapper">
                    Load fonts while the page is loading. Might cause a content
                    shift.
                  </div>
                </div>
              </div>
              <div className="account_fields__action">
                <Switch
                  checked={seoData.isFontAsync}
                  loading={isWaitingForServerResponse}
                  onChange={() => {
                    this.confirmDisallow({
                      seo_data: {
                        ...seoData,
                        isFontAsync: !seoData.isFontAsync,
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <Divider className="settings__divider" />
        {
          <WebsiteLanguageSettings
            auth={this.props.auth}
            user={this.props.user}
            currentWebsite={this.props.currentWebsite}
            websites={this.props.websites}
            saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
            toggleWaitingForWebsiteLanguagesUpdate={
              this.props.toggleWaitingForWebsiteLanguagesUpdate
            }
          />
        }

        <Divider className="settings__divider" />
        {
          <WebsiteRedirects
            auth={this.props.auth}
            user={this.props.user}
            currentWebsite={this.props.currentWebsite}
            websites={this.props.websites}
            saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
            fullWebsiteUrl={this.props.fullWebsiteUrl}
            toggleWaitingForCommonWebsiteUpdate={
              this.props.toggleWaitingForCommonWebsiteUpdate
            }
          />
        }

        <Divider className="settings__divider" />

        <div className="settings-box__input">
          <Popconfirm
            placement="top"
            title={
              <div style={{ width: 350 }}>{confirmDisallowDetailsMessage}</div>
            }
            onConfirm={() => {
              this.confirmDisallow({
                is_disallow_crawling_bots: !isDisallowAllActive,
              });
            }}
            okText={confirmDisallowButtonText}
            cancelText="Cancel"
          >
            <Checkbox
              className="website-seo-settings__disallow"
              disabled={isWaitingForServerResponse}
              checked={!isDisallowAllActive}
            >
              Allow robots (including Google&trade;) to crawl your website.{" "}
            </Checkbox>
          </Popconfirm>
        </div>
      </div>
    );
  }
}

export default WebsiteSEOsettings;
