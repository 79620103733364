export const popularLanguagesWithEmoji = [
    {"english": "english", "native": "English", "flag": "🇺🇸🇬🇧", "code": "en"},
    {"english": "chinese", "native": "Zhongwen", "flag": "🇨🇳", "code": "zh"},
    {"english": "chinese (Traditional)", "native": "繁體字", "flag": "🇹🇼", "code": "zh-TW"},
    {"english": "spanish", "native": "Espanol", "flag": "🇪🇸", "code": "es"},
    {"english": "french", "native": "Francais", "flag": "🇫🇷", "code": "fr"},
    {"english": "japanese", "native": "Nihongo", "flag": "🇯🇵", "code": "ja"},
    {"english": "korean", "native": "Hangugeo", "flag": "🇰🇷", "code": "ko"},
    {"english": "german", "native": "Deutsch", "flag": "🇩🇪", "code": "de"},
    {"english": "dutch", "native": "Nederlands", "flag": "🇳🇱", "code": "nl"},
    {"english": "portuguese", "native": "Portugues", "flag": "🇵🇹", "code": "pt"},
    {"english": "portuguese", "native": "Português Brasileiro", "flag": "🇧🇷", "code": "pt-br"},
    {"english": "hindi", "native": "Hindi", "flag": "🇮🇳", "code": "hi"},
    {"english": "bengali", "native": "Bengali", "flag": "🇧🇩", "code": "bn"},
    {"english": "indonesian", "native": "Bahasa", "flag": "🇮🇩", "code": "id"},
    {"english": "russian", "native": "Russian", "flag": "🇷🇺", "code": "ru"},
    {"english": "danish", "native": "Dansk", "flag": "🇩🇰", "code": "da"},
    {"english": "estonian", "native": "Eesti", "flag": "🇪🇪", "code": "et"},
    {"english": "finnish", "native": "Suomi", "flag": "🇫🇮", "code": "fi"},
    {"english": "latvian", "native": "Latviesu", "flag": "🇱🇻", "code": "lv"},
    {"english": "lithuanian", "native": "Lietuviu", "flag": "🇱🇹", "code": "lt"},
    {"english": "ukrainian", "native": "Ukrainian", "flag": "🇺🇦", "code": "uk"},
    {"english": "greek", "native": "Hellenike", "flag": "🇬🇷", "code": "el"},
    {"english": "swedish", "native": "Svenska", "flag": "🇸🇪", "code": "sv"},
    {"english": "polish", "native": "Polski", "flag": "🇵🇱", "code": "pl"},
    {"english": "turkish", "native": "Turkce", "flag": "🇹🇷", "code": "tr"},
    {"english": "italian", "native": "Italiana", "flag": "🇮🇹", "code": "it"},
    {"english": "thai", "native": "Thai", "flag": "🇹🇭", "code": "th"},
    {"english": "lao", "native": "Lao", "flag": "🇱🇦", "code": "lo"},
    {"english": "khmer", "native": "Khmer", "flag": "🇰🇭", "code": "km"},
    {"english": "burmese", "native": "Burmese", "flag": "🇲🇲", "code": "my"},
    {"english": "romanian", "native": "Romana", "flag": "🇷🇴", "code": "ro"},
    {"english": "norwegian", "native": "Norsk", "flag": "🇳🇴", "code": "no"},
    {"english": "czech", "native": "Czech", "flag": "🇨🇿", "code": "cs"},
    {"english": "hungarian", "native": "Magyar", "flag": "🇭🇺", "code": "hu"},
    {"english": "bulgarian", "native": "Bulgarian", "flag": "🇧🇬", "code": "bg"},
    {"english": "croatian", "native": "Hrvatski", "flag": "🇭🇷", "code": "hr"},
    {"english": "irish", "native": "Gaeilge", "flag": "🇮🇪", "code": "ga"},
    {"english": "maltese", "native": "Malti", "flag": "🇲🇹", "code": "mt"},
    {"english": "slovak", "native": "Slovak", "flag": "🇸🇰", "code": "sk"},
    {"english": "slovene", "native": "Slovene", "flag": "🇸🇮", "code": "sl"},
    {"english": "malay", "native": "Bahasa Melayu", "flag": "🇲🇾", "code": "ms"},
    {"english": "vietnamese", "native": "Tiếng Việt", "flag": "🇻🇳", "code": "vi"},
    {"english": "azerbaijani", "native": "Azərbaycan", "flag": "🇦🇿", "code": "az"},
    {"english": "arabic", "native": "Arabic", "flag": "🇸🇦", "code": "ar"},
    {"english": "icelandic", "native": "Íslenska", "flag": "🇮🇸", "code": "is"},
    {"english": "serbian", "native": "Srpski", "flag": "🇷🇸", "code": "sr"},
]; 
