import _ from "lodash";
import { CmsType } from "../enums/enums";
import { sheetsApi } from "../data/urls";
import { formatGoogleSheetUrl } from "./formatGoogleSheetUrl";

export const getFormattedCmsUrl = (content: any) => {
  if (!content) return "";
  const cmsType = _.get(content, "cmsType") || CmsType.GOOGLE_SHEETS;
  const unicornCollectionId = _.get(content, "unicornCollectionId", "");
  const customApiUrl = _.get(content, "customApiUrl", "");
  const cmsUrl = _.get(content, "cmsUrl", "");

  if (cmsType === CmsType.UNICORN) {
    if (!unicornCollectionId) return;
    return `${sheetsApi}/cms/items?collection_id=${unicornCollectionId}`;
  }
  if (cmsType === CmsType.GOOGLE_SHEETS) {
    if (!cmsUrl) return "";
    return formatGoogleSheetUrl(cmsUrl);
  }
  if (cmsType === CmsType.CUSTOM) {
    if (!customApiUrl) return "";
    return customApiUrl;
  }

  return "";
};
