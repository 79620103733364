import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {buttonStyles} from "../../data/buttonStyles";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    isDarkBg: boolean,
}

class CtaButton03 extends Component<Props>{
    generator = new generateContentDataObject();
    componentWillMount(): void {
      const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;

            this.props.saveContentInStore(this.generator.setUpTitle({
                titleText: 'Create a free account and build your first project today! Yay!',
                isDefaultEmpty
            }));

            this.props.saveContentInStore(this.generator.setUpSubtitleText({
                subtitleText: 'Get started today',
                isDefaultEmpty
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
                activeOption: callToActionOptions.buttons,
                maxButtons: 3,
                isDefaultEmpty,

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "Get it",
                        buttonHref: '/sign-up',
                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.altAccentBg,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'Whoa!'
                    }),

                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "About us",
                        buttonHref: '/features',
                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.whiteOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'Read'
                    }),

                    // ghost object
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.ghost.typeTitle,
                        buttonColor: buttonStyles.ghost.whiteOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'View'
                    })
                ]
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        return (
            <section>


                <div className="cta_button-03">
                    <div className="container container--mid">
                        <div className="cta_button-03__wrapper">
                            <div className="cta_button-03__title_box">

                                {subtitleText.length > 0 && <div className={"subtitle-text content_box cta_button-03__text " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: subtitleText}}/>}

                                {title.length > 0 && <h3 className={"title-text cta_button-03__title " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: title}}/>}

                            </div>
                            <div className="cta_button-03__button_box">

                              <WebsiteCTA componentItem={this.props.componentItem} />

                            </div>
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}

export default CtaButton03;
