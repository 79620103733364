import React from "react";
import ConditionalDiv from "../components/ConditionalDiv";
import { formatImageUrl } from "../helpers/strings/formatImageUrl";

interface Props {
  src: string;
  alt?: string;
  className?: string;
  dataWidth?: number;
  dataHeight?: number;
  setTabsHeightOnChange?: boolean;
  containerClassName?: string;
}

const ImageElement = (props: Props): JSX.Element => {
  const {
    src,
    alt,
    className,
    dataWidth,
    dataHeight,
    setTabsHeightOnChange,
    containerClassName,
  } = props;

  if (!src) return null;

  const handleOnLoad = () => {
    if (setTabsHeightOnChange) {
      if (window.unicornplatform && window.unicornplatform.tabs) {
        window.unicornplatform.tabs.setAll();
      }
    }
  };

  return (
    <ConditionalDiv
      condition={!!containerClassName}
      className={containerClassName}
    >
      <img
        src={formatImageUrl(src)}
        className={className}
        alt={alt}
        data-width={dataWidth}
        data-height={dataHeight}
        onLoad={handleOnLoad}
      />
    </ConditionalDiv>
  );
};

export default ImageElement;
