import React, { useEffect, useRef } from "react";
import { getSubdomain } from "../../helpers/custom_domain/getSubdomain";
import { Alert, Icon } from "antd";
import { pingDomainAsync } from "../../helpers/async/pingDomainAsync";
import { AuthState } from "../../store/auth/types";
import { updateWebsiteAsync } from "../../helpers/async/updateWebsiteAsync";
import { openNotification } from "../../helpers/openNotification";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_TITLE,
  notificationDuration,
} from "../../data/constants";

const INTERVAL_TIME = 1000 * 30;
const INITIAL_DELAY = 1000 * 5;

interface Props {
  currentWebsite: any;
  setIsTransferSuccess: any;
  auth: AuthState;
  saveWebsiteDataInStore: any;
}
const PingDomainMenu = (props: Props) => {
  const { currentWebsite, auth, setIsTransferSuccess, saveWebsiteDataInStore } =
    props;
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const finishPing = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    updateWebsiteAsync({
      accessToken: props.auth.accessToken,
      subdomain: currentWebsite.subdomain,
      data: {
        domain_data: {
          ...currentWebsite.domain_data,
          is_ping: false,
        },
      },
    })
      .then((response) => {
        const dataToStore = {
          data: {
            ...currentWebsite,
            domain_data: {
              ...currentWebsite.domain_data,
              is_ping: false,
            },
          },
        };
        saveWebsiteDataInStore(dataToStore, currentWebsite.subdomain);
        setIsTransferSuccess(true);
      })
      .catch((error) => {
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
        console.log(error);
        console.log(error.response);
      });
  };

  const pingDomain = () => {
    const subdomain = getSubdomain(currentWebsite.custom_domain);
    const fullDomain = !!subdomain
      ? currentWebsite.custom_domain
      : `www.${currentWebsite.custom_domain}`;
    pingDomainAsync({
      accessToken: auth.accessToken,
      data: {
        domain: fullDomain,
      },
    })
      .then((response) => {
        const { data } = response;
        if (data.status === 200 && data.is_cloudflare) {
          finishPing();
          return;
        }
        if (!intervalRef.current) {
          intervalRef.current = setInterval(pingDomain, INTERVAL_TIME);
        }
      })
      .catch((error) => {
        if (!intervalRef.current) {
          intervalRef.current = setInterval(pingDomain, INTERVAL_TIME);
        }
        console.log(error);
        console.log(error.response);
      });
  };

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      pingDomain();
    }, INITIAL_DELAY);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <Alert
        message={
          <div>
            <p>
              Initializing domain{" "}
              <Icon
                style={{ color: "#1890ff", marginLeft: 4 }}
                type="loading"
              />
            </p>
            <p>
              You've successfully updated the domain. Now we need just a few
              more moments to initialize it.
            </p>
            <p style={{ marginBottom: 0 }}>This might take a minute or two.</p>
          </div>
        }
        type="info"
      />
    </div>
  );
};

export default PingDomainMenu;
