import { Alert, Button, Icon, Steps } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { GetCurrentStoreData, WebsitesState } from "../../store/websites/types";
import { getSubdomain } from "../../helpers/custom_domain/getSubdomain";
import { connectCustomDomainAsync } from "../../helpers/async/connectCustomDomainAsync";
import { AuthState } from "../../store/auth/types";
import _, { update } from "lodash";
import DnsRecordsTable from "./DnsRecordsTable";
import { MetaState } from "../../store/meta/types";
import { updateWebsiteAsync } from "../../helpers/async/updateWebsiteAsync";
import "./TransferDomainMenu.scss";
import { customDomainUpdateWaitingForServer } from "../../store/websites/actions";
import {
  DEFAULT_ERROR_TITLE,
  DEFAULT_ERROR_MESSAGE,
  notificationDuration,
} from "../../data/constants";
import { openNotification } from "../../helpers/openNotification";
import { sendToDiscordAsync } from "../../helpers/sendToDiscordAsync";
import { UserState } from "../../store/user/types";
import { validateDnsAsync } from "../../helpers/async/validateDnsAsync";

const VALIDATE_CLOUDFLARE_DNS_INTERVAL = 5000;

const { Step } = Steps;

interface FirstStepProps extends Props {
  next: () => void;
  prev: () => void;
}
const FirstStep = (props: FirstStepProps) => {
  const { next, getCurrentStoreData, auth, saveWebsiteDataInStore, websites } =
    props;
  const { currentWebsite } = getCurrentStoreData();
  const customDomain = currentWebsite.custom_domain;
  const subdomain = getSubdomain(customDomain);
  const handleOnUpdateSetup = () => {
    props.customDomainUpdateWaitingForServer(true);
    connectCustomDomainAsync({
      websiteId: currentWebsite.id,
      accessToken: auth.accessToken,
      operationType: "allocate",
      data: {
        custom_domain: customDomain,
        subdomain_part: subdomain,
      },
    })
      .then((response) => {
        const dataToStore = {
          data: {
            ...currentWebsite,
            domain_data: _.get(response, "data.data.domain_data"),
            domain_setup_version: _.get(
              response,
              "data.data.domain_setup_version"
            ),
          },
        };
        saveWebsiteDataInStore(dataToStore, currentWebsite.subdomain);
        try {
          sendToDiscordAsync({
            url: "https://discord.com/api/webhooks/1100388665613623309/igKtHHzaFsuafViRTWYtz_JnVoeqTh4HNDbPnYoxe1tdGNVDFs97DNT1vuqN2eGBVFo-",
            embeds: [
              {
                title: `User started transfer domain`,
                description: `
            Domain: ${currentWebsite.custom_domain}
            Website id: ${currentWebsite.id}
            User email: ${_.get(props.user, "data.email")}
            User id: ${_.get(props.user, "data.id")}
                `,
                color: 16776960,
              },
            ],
          });
        } catch (error) {
          console.error("Error while sending data to Discord", error);
        }
        next();
      })
      .catch((error) => {
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
        console.log(error);
        console.log(error.response);
      })
      .finally(() => {
        props.customDomainUpdateWaitingForServer(false);
      });
  };
  return (
    <div>
      <h3 style={{ fontSize: 14, fontWeight: 600 }}>
        Upgrade your domain setup{" "}
        <Icon
          type="warning"
          style={{ color: "#FFA800", marginLeft: 4, fontSize: 13 }}
        />
      </h3>
      <p style={{ marginBottom: 12 }}>
        You have a legacy custom domain setup. Upgrade to a new version for
        faster loading speed, improved uptime, and DDOS protection.
      </p>
      {!subdomain && (
        <Alert
          message={
            <div>
              After the upgrade, your website will be served with "www" prefix
              (www.{currentWebsite.custom_domain}). This may cause a temporary
              minor decrease in SEO score. It'll get back to normal
              automatically after 1-2 weeks.
            </div>
          }
          type="warning"
          style={{ marginBottom: 12 }}
        />
      )}
      <p style={{ marginBottom: 12 }}>
        To upgrade your setup, you will need to update DNS records in your
        domain registrar dashboard.
      </p>
      <Button
        type="primary"
        onClick={handleOnUpdateSetup}
        loading={websites.isWaitingForCustomDomainUpdateResponse}
      >
        Start upgrade
      </Button>
    </div>
  );
};

interface SecondStepProps extends FirstStepProps {
  domainData: any;
  handleOnFinish: () => void;
  subdomain: string;
}
const SecondStep = (props: SecondStepProps) => {
  const {
    getCurrentStoreData,
    meta,
    next,
    domainData,
    auth,
    saveWebsiteDataInStore,
    websites,
    customDomainUpdateWaitingForServer,
    setIsTransferSuccess,
    handleOnFinish,
    subdomain,
  } = props;
  const { currentWebsite } = getCurrentStoreData();
  const cname = domainData.domains_cname ? domainData.domains_cname[0] : "";
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>("");

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        props.customDomainUpdateWaitingForServer(false);
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const enableDomain = () => {
    return new Promise((resolve, reject) => {
      connectCustomDomainAsync({
        websiteId: currentWebsite.id,
        accessToken: auth.accessToken,
        operationType: "enable",
        data: {
          custom_domain: currentWebsite.custom_domain,
          subdomain_part: subdomain,
        },
      })
        .then((response) => {
          const dataToStore = {
            data: {
              ...currentWebsite,
              domain_data: _.get(response, "data.data.domain_data"),
            },
          };
          saveWebsiteDataInStore(dataToStore, currentWebsite.subdomain);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const validateCloudflareDns = () => {
    validateDnsAsync({
      accessToken: auth.accessToken,
      data: {
        domain: !!subdomain
          ? currentWebsite.custom_domain
          : `www.${currentWebsite.custom_domain}`,
        recordType: "CNAME",
        resolver: "Cloudflare",
      },
    })
      .then((response) => {
        const results = _.get(response, "data.results", []).filter((i) => !!i);
        if (results[0] === cname) {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
          }
          enableDomain()
            .then(() => {
              if (!subdomain) {
                props.customDomainUpdateWaitingForServer(false);
                next();
              } else {
                handleOnFinish();
              }
            })
            .catch((error) => {
              if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
              }
              openNotification(
                DEFAULT_ERROR_TITLE,
                DEFAULT_ERROR_MESSAGE,
                "OK",
                "error",
                notificationDuration.medium
              );
              console.log(error);
              console.log(error.response);
              props.customDomainUpdateWaitingForServer(false);
            });
        } else {
          if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
              validateCloudflareDns();
            }, VALIDATE_CLOUDFLARE_DNS_INTERVAL);
          }
        }
      })
      .catch((error) => {
        props.customDomainUpdateWaitingForServer(false);
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
        console.log(error);
        console.log(error.response);
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      });
  };

  const handleOnClick = () => {
    props.customDomainUpdateWaitingForServer(true);
    validateDnsAsync({
      accessToken: auth.accessToken,
      data: {
        domain: !!subdomain
          ? currentWebsite.custom_domain
          : `www.${currentWebsite.custom_domain}`,
        recordType: "CNAME",
      },
    })
      .then((response) => {
        const results = _.get(response, "data.results", []).filter((i) => !!i);
        if (results.length === 0) {
          setErrorMessage(
            <div>
              <p style={{ marginBottom: 12 }}>DNS record not found.</p>
              <p style={{ marginBottom: 12 }}>
                Please make sure you have added the following DNS record:
              </p>
              <div style={{ marginBottom: 12 }}>
                <DnsRecordsTable
                  currentWebsite={currentWebsite}
                  meta={meta}
                  customTable={{
                    type: "CNAME",
                    name: !!subdomain ? subdomain : "www",
                    value: cname,
                  }}
                />
              </div>
              <p>It may take a few minutes for the record to propagate.</p>
            </div>
          );
          props.customDomainUpdateWaitingForServer(false);
          return;
        }
        if (!results.includes(cname)) {
          setErrorMessage(
            <div>
              <p style={{ marginBottom: 12 }}>DNS record is incorrect.</p>
              <p style={{ marginBottom: 12 }}>
                Your current "CNAME" record for "
                {!!subdomain ? subdomain : "www"}" has the value "{results[0]}
                ". Please change it to the following:
              </p>
              <div style={{ marginBottom: 12 }}>
                <DnsRecordsTable
                  currentWebsite={currentWebsite}
                  meta={meta}
                  customTable={{
                    type: "CNAME",
                    name: !!subdomain ? subdomain : "www",
                    value: cname,
                  }}
                />
              </div>
              <p>It may take a few minutes for the record to propagate.</p>
            </div>
          );
          props.customDomainUpdateWaitingForServer(false);
          return;
        }
        validateCloudflareDns();
      })
      .catch((error) => {
        props.customDomainUpdateWaitingForServer(false);
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
        console.log(error);
        console.log(error.response);
      });
  };

  return (
    <div>
      <h3 style={{ fontSize: 14, fontWeight: 600 }}>
        Update DNS records{!subdomain && " (Step 1/2)"}
      </h3>
      <p style={{ marginBottom: 12 }}>
        Update DNS records in your domain registrar dashboard.
      </p>
      <p style={{ marginBottom: 12 }}>
        Find DNS record with type "A" and name "
        {!!subdomain ? subdomain : "www"}". Change its type from "A" to "CNAME"
        and value to{" "}
        <span
          style={{
            backgroundColor: "#F0F0F0",
            padding: "3px 6px",
            borderRadius: "4px",
            userSelect: "all",
          }}
        >
          {cname}
        </span>
      </p>
      <p style={{ marginBottom: 6 }}>New record should look like this:</p>
      <div style={{ marginBottom: 16 }}>
        <DnsRecordsTable
          currentWebsite={currentWebsite}
          meta={meta}
          customTable={{
            type: (
              <div>
                <span
                  style={{ textDecoration: "line-through", userSelect: "none" }}
                >
                  &nbsp;A&nbsp;
                </span>
                <br style={{ userSelect: "none" }} />
                CNAME
              </div>
            ),
            name: !!subdomain ? subdomain : "www",
            value: (
              <div>
                <span
                  style={{ textDecoration: "line-through", userSelect: "none" }}
                >
                  &nbsp;xxx.xxx.xxx.xxx&nbsp;
                </span>
                <br style={{ userSelect: "none" }} />
                {cname}
              </div>
            ),
          }}
        />
      </div>
      {!subdomain && (
        <Alert
          message={`After you update the record, the "www" version of your website (www.${currentWebsite.custom_domain}) may be temporarily unavailable. However, the main version (${currentWebsite.custom_domain}) will remain accessible.`}
          type="warning"
          style={{ marginBottom: 12 }}
        />
      )}
      {!!subdomain && (
        <Alert
          message={`After you update the record, your website may become temporarily unavailable. This will be resolved after you click "Finish upgrade" (if your DNS records are correct).`}
          type="warning"
          style={{ marginBottom: 12 }}
        />
      )}
      <p style={{ marginBottom: 12 }}>
        If you use Cloudflare, please set the record's "Proxy status" to "DNS
        only".
      </p>
      {!subdomain && (
        <p style={{ marginBottom: 12 }}>
          Update the record and click the button below to continue.
        </p>
      )}
      {!!subdomain && (
        <p style={{ marginBottom: 12 }}>
          Update the record and click the button below to finish the upgrade.
        </p>
      )}
      <Button
        type="primary"
        onClick={handleOnClick}
        loading={websites.isWaitingForCustomDomainUpdateResponse}
      >
        {!!subdomain ? "Finish upgrade" : "Next step"}
      </Button>
      {errorMessage && !websites.isWaitingForCustomDomainUpdateResponse && (
        <Alert style={{ marginTop: 16 }} message={errorMessage} type="error" />
      )}
    </div>
  );
};

interface ThirdStepProps extends FirstStepProps {
  domainData: any;
  handleOnFinish: () => void;
  subdomain: string;
}

const ThirdStep = (props: ThirdStepProps) => {
  const {
    getCurrentStoreData,
    meta,
    next,
    domainData,
    auth,
    saveWebsiteDataInStore,
    websites,
    customDomainUpdateWaitingForServer,
    setIsTransferSuccess,
    handleOnFinish,
    subdomain,
  } = props;
  const { currentWebsite } = getCurrentStoreData();
  const aRecord = domainData.domains_a ? domainData.domains_a[0] : "";
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>("");

  const handleOnClick = () => {
    props.customDomainUpdateWaitingForServer(true);
    validateDnsAsync({
      accessToken: auth.accessToken,
      data: {
        domain: currentWebsite.custom_domain,
        recordType: "A",
      },
    })
      .then((response) => {
        const results = _.get(response, "data.results", []).filter((i) => !!i);
        if (results.length === 0) {
          setErrorMessage(
            <div>
              <p style={{ marginBottom: 12 }}>DNS record not found.</p>
              <p style={{ marginBottom: 12 }}>
                Please make sure you have added the following DNS record:
              </p>
              <div style={{ marginBottom: 12 }}>
                <DnsRecordsTable
                  currentWebsite={currentWebsite}
                  meta={meta}
                  customTable={{
                    type: "A",
                    name: "@",
                    value: aRecord,
                  }}
                />
              </div>
              <p>It may take a few minutes for the record to propagate.</p>
            </div>
          );
          props.customDomainUpdateWaitingForServer(false);
          return;
        }
        if (!results.includes(aRecord)) {
          setErrorMessage(
            <div>
              <p style={{ marginBottom: 12 }}>DNS record is incorrect.</p>
              <p style={{ marginBottom: 12 }}>
                Your current "A" record for "@" has the value "{results[0]}
                ". Please change it to the following:
              </p>
              <div style={{ marginBottom: 12 }}>
                <DnsRecordsTable
                  currentWebsite={currentWebsite}
                  meta={meta}
                  customTable={{
                    type: "A",
                    name: "@",
                    value: aRecord,
                  }}
                />
              </div>
              <p>It may take a few minutes for the record to propagate.</p>
            </div>
          );
          props.customDomainUpdateWaitingForServer(false);
          return;
        }
        handleOnFinish();
      })
      .catch((error) => {
        props.customDomainUpdateWaitingForServer(false);
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
        console.log(error);
        console.log(error.response);
      });
  };

  return (
    <div>
      <h3 style={{ fontSize: 14, fontWeight: 600 }}>
        Update DNS records (Step 2/2)
      </h3>
      <p style={{ marginBottom: 12 }}>
        Find DNS record with type "A" and name "@". Change its value to{" "}
        <span
          style={{
            backgroundColor: "#F0F0F0",
            padding: "3px 6px",
            borderRadius: "4px",
            userSelect: "all",
          }}
        >
          {aRecord}
        </span>
      </p>
      <p style={{ marginBottom: 6 }}>New record should look like this:</p>
      <div style={{ marginBottom: 16 }}>
        <DnsRecordsTable
          currentWebsite={currentWebsite}
          meta={meta}
          customTable={{
            type: "A",
            name: "@",
            value: (
              <div>
                <span
                  style={{ textDecoration: "line-through", userSelect: "none" }}
                >
                  &nbsp;xxx.xxx.xxx.xxx&nbsp;
                </span>
                <br style={{ userSelect: "none" }} />
                {aRecord}
              </div>
            ),
          }}
        />
      </div>
      <p style={{ marginBottom: 12 }}>
        If you use Cloudflare, please set the record's "Proxy status" to "DNS
        only".
      </p>
      <p style={{ marginBottom: 12 }}>
        Update the record and click the button below to finish the upgrade.
      </p>
      <Button
        type="primary"
        onClick={handleOnClick}
        loading={websites.isWaitingForCustomDomainUpdateResponse}
      >
        Finish upgrade
      </Button>
      {errorMessage && !websites.isWaitingForCustomDomainUpdateResponse && (
        <Alert style={{ marginTop: 16 }} message={errorMessage} type="error" />
      )}
    </div>
  );
};

interface StepsMenuProps extends Props {}
const StepsMenu = (props: StepsMenuProps) => {
  const { getCurrentStoreData } = props;
  const { currentWebsite } = getCurrentStoreData();
  const domainData: any = _.get(currentWebsite, "domain_data", {});
  const subdomain = getSubdomain(currentWebsite.custom_domain);

  const [current, setCurrent] = useState(domainData.transfer_step || 0);

  useEffect(() => {
    setCurrent(domainData.transfer_step || 0);
  }, [domainData.transfer_step]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleOnFinish = () => {
    customDomainUpdateWaitingForServer(true);
    updateWebsiteAsync({
      accessToken: props.auth.accessToken,
      subdomain: currentWebsite.subdomain,
      data: {
        domain_data: {
          ...currentWebsite.domain_data,
          is_transfer: false,
          is_ping: true,
        },
      },
    })
      .then((response) => {
        const dataToStore = {
          data: {
            ...currentWebsite,
            domain_data: {
              ...currentWebsite.domain_data,
              is_transfer: false,
              is_ping: true,
            },
          },
        };
        props.saveWebsiteDataInStore(dataToStore, currentWebsite.subdomain);
      })
      .catch((error) => {
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
        console.log(error);
        console.log(error.response);
      })
      .finally(() => {
        props.customDomainUpdateWaitingForServer(false);
      });
  };

  const steps = [
    {
      title: "Start",
      content: <FirstStep {...props} next={next} prev={prev} />,
    },
    {
      title: "Update DNS",
      content: (
        <SecondStep
          {...props}
          next={next}
          prev={prev}
          domainData={domainData}
          handleOnFinish={handleOnFinish}
          subdomain={subdomain}
        />
      ),
    },
    {
      title: "Finish",
      content: (
        <ThirdStep
          {...props}
          next={next}
          prev={prev}
          domainData={domainData}
          handleOnFinish={handleOnFinish}
          subdomain={subdomain}
        />
      ),
    },
  ];

  return (
    <div style={{ marginTop: 12 }}>
      <Steps current={current} size="small">
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div>{steps[current].content}</div>
    </div>
  );
};

interface Props {
  getCurrentStoreData: GetCurrentStoreData;
  websites: WebsitesState;
  auth: AuthState;
  meta: MetaState;
  user: UserState;

  saveWebsiteDataInStore: any;
  customDomainUpdateWaitingForServer: any;
  setIsTransferSuccess: any;
}
const TransferDomainMenu = (props: Props) => {
  return (
    <div style={{ marginTop: 20 }} className="transfer-domain-menu">
      <StepsMenu {...props} />
      <div style={{ paddingBottom: 64 }}></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    websites: state.websites,
    auth: state.auth,
    meta: state.meta,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  getCurrentStoreData,
  customDomainUpdateWaitingForServer,
})(TransferDomainMenu);
