import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import MockupWithContent from "../../components/MockupWithContent";
import _ from "lodash";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Header63 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty, defaultCtaOption } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpGraphics({
          activeOption: GraphicsOptions.image,
          userCanAddItems: false,
          maxItems: 2,

          label: {
            [GraphicsOptions.image]: "Upload the images:",
            [GraphicsOptions.video]: "Enter URL to Youtube video:",
            default: "Upload the images:",
          },

          fieldTitle: {
            [GraphicsOptions.image]: "Edit the app screenshots",
            [GraphicsOptions.video]: "Video",
            default: "Edit the app screenshots",
          },

          fieldInfo: {
            [GraphicsOptions.image]: "",
            [GraphicsOptions.video]: "",
            default: "",
          },
          items: [
            this.generator.createGraphicsContentObject({
              imageUrl: DEFAULT_IMAGES_CDN.UI_MOBILE_DASHBOARD,
              videoUrl:
                "https://up-blog-prod.s3.amazonaws.com/neon_compressed.mp4",
              width: 412,
              height: 892,
            }),
          ],
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpTitle({
          titleText: "Intelligent Website Builder for Your Mobile App",
          isDefaultEmpty
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpMockups({
          items: [
            this.generator.createMockupsContentObject({
              type: "mobile",
              deviceId: "iphone_11_pro_max",
              optionId: "space_grey",
              fileName: "apple_iphone_11_pro_max_space_grey.png",
              humanTitle: "iPhone 11 Pro Max",
            }),
          ],
        })
      );
      this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
      this.props.saveContentInStore(
        this.generator.setUpCta({
          activeOption: defaultCtaOption || callToActionOptions.buttons,
          isDefaultEmpty,

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "Get it",
              buttonHref: "/download-ios",
              buttonStyleType: buttonStyles.mobileAppStore.typeTitle,
              buttonMobileAppStoreType: buttonStyles.mobileAppStore.ios,
              buttonColor: buttonStyles.regular.accentBg,
              buttonIsTargetBlank: false,
              pillPrefix: "Whoa!",
            }),

            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "About us",
              buttonHref: "/download-android",
              buttonStyleType: buttonStyles.mobileAppStore.typeTitle,
              buttonMobileAppStoreType: buttonStyles.mobileAppStore.android,
              buttonColor: buttonStyles.regular.blackOutline,
              buttonIsTargetBlank: false,
              pillPrefix: "New",
            }),

            // ghost object
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.ghost.typeTitle,
              buttonColor: buttonStyles.ghost.accentOutline,
              buttonIsTargetBlank: false,
              pillPrefix: "View",
            }),
          ],
        })
      );
    }
  }

  render() {
    this.props.connectDefaultFormIntegration(this.props.componentItem);

    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );
    let graphicsObject = this.props.componentItem.componentData.graphics.content
      .items[0]; //This component has exactly one item and more can not be added (see createGraphicsContentObject() above).

    let mockup = displayTitleMarkupText(
      this.props.componentItem.componentData.mockups.content.items[0]
    );

    const currentActiveOption: GraphicsOptions = _.get(
      this.props.componentItem,
      ["componentData", "graphics", "activeOption"]
    );

    return (
      <header
        className={`header-63 graphics-${currentActiveOption} default-graphics-${GraphicsOptions.image}`}
      >
        <div className="header-63__wrapper">
          <div className="header-63__left">
            <div className="header-63__left_content">
              {title.length > 0 && (
                <h1
                  className={
                    "title-text heading header-63__heading " +
                    (this.props.isDarkBg ? "text-white" : "")
                  }
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}

              {subtitleText.length > 0 && (
                <p
                  className={
                    "subtitle-text header-63__text content_box " +
                    (this.props.isDarkBg ? "text-white" : "")
                  }
                  dangerouslySetInnerHTML={{ __html: subtitleText }}
                />
              )}
              <div className="buttons-set header-63__buttons">
                <WebsiteCTA
                  componentItem={this.props.componentItem}
                  isDarkBg={this.props.isDarkBg}
                />
              </div>
            </div>
          </div>
          <div className="header-63__right">
            <div className="header-63__mockup">
              <MockupWithContent
                mockupObject={mockup}
                graphicsObject={graphicsObject}
                isDarkBg={this.props.isDarkBg}
                currentActiveOption={currentActiveOption}
                HTMLClassNames="graphics-HTML__editor-code_mobile-mockup"
              />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header63;
